import Vue from 'vue';
import 'iCheck';
import "niceSelect";
import moment from 'moment';
// import moment = require('moment');
// import "@/plugins/js/daterangepicker.js";

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

Vue.directive('icheckStyle', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el, binding) {
    // 聚焦元素
    // binding.value=false;
    // console.log(binding.modifiers.single)
    const ich = $(el).iCheck({
      checkboxClass: 'icheckbox_square-grey',
      radioClass: 'iradio_square-grey'
    });
    // console.log(this);
    ich.iCheck('update');
    ich.on("ifToggled", function (event) {

      let bvalue = binding.value;
      let value = event.target.value;
      let inputtype = event.target.type;
      if (inputtype == 'checkbox' && !binding.modifiers.single) {
        // console.log("checkbox");
        if (event.target.checked) {
          if (value == "all") {
            bvalue.length = 0;
          } else {
            let allindex = bvalue.indexOf("all");
            if (allindex > -1) {
              bvalue.splice(allindex, 1);
            }
          }
          let index = bvalue.indexOf(value);
          if (index == -1) {
            bvalue.push(value);
          }

        } else {
          let index = bvalue.indexOf(value);
          if (index > -1) {
            bvalue.splice(index, 1);
          }
        }
      } else if (inputtype == 'radio' && !binding.modifiers.single) {

        bvalue.length = 0;
        bvalue.push(value);
      } else if (binding.modifiers.single) {
        // console.log(binding);
        const check = event.target.checked;
        bvalue[binding.arg] = event.target.checked ? value : "0"
      }
      Vue.nextTick(() => {
        $(el).iCheck('update');
      });
    })
  },
  update: (el, binding) => {
    if (binding.modifiers.iszore) {
      let bvalue = binding.value;
      if (bvalue.length == 0) {
        let value = el.value;
        bvalue.push(value);
        $(el).iCheck("update");
      }
    }
    Vue.nextTick(() => {
      $(el).iCheck('update');
    });
  }
})
Vue.directive('magnificPopup', {
  inserted: (el, binding) => {
    const opened = (binding.value) ? (binding.value.start || (() => {
      return "";
    })) : (() => {});
    const close = (binding.value) ? (binding.value.close || (() => {
      return "";
    })) : (() => {});
    // console.log(binding);
    var magnificPopup = $.magnificPopup.instance;
    // console.log($.magnificPopup);
    $(el).magnificPopup({
      type: 'inline',
      fixedContentPos: true,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: false,
      removalDelay: 300,
      closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
      mainClass: 'my-mfp-zoom-in',
      alignTop: binding.modifiers.alignTop,
      callbacks: {
        beforeOpen: opened,
        close: close
      }
    });
    // mfp-content
    //mfp-container mfp-inline-holder mfp-content
    //mfp-container mfp-inline-holder
    //mfp-wrap mfp-close-btn-in mfp-align-top mfp-auto-cursor my-mfp-zoom-in mfp-ready
    //mfp-wrap mfp-close-btn-in mfp-auto-cursor my-mfp-zoom-in mfp-ready
  }
});
Vue.directive('niceSelect', {
  inserted: (el, binding) => {
    let nice;
    nice = $(el).niceSelect();
    // console.log(el);
    // console.log(binding);
    let values = [];
    $.each(binding.modifiers, (keys, item) => {
      values = keys;
    });
    nice.on('change', function (data) {
      binding.value[values] = $(el).val();
    })
  },
  update: (el, binding) => {
    $(el).niceSelect("update");
  },
  componentUpdated: (el) => {
    $(el).niceSelect("update");
  }
});
Vue.directive("toTop", {
  inserted: (el, binding) => {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() != 0) {
        $(el).show();
      } else {
        $(el).hide();
      }
    });
    $(el).on('click', function () {
      $('body,html').scrollTop(0);
    });
  }
});
Vue.directive("daterangepicker", {
  inserted: (el, binding) => {

  }
});

const charbox = {
  position(data, el) {
    // console.log(data);
    let boxwidth = 100 / data.days;
    const start = data.start;
    // console.log(start);
    const days = data.totaldays;
    $(el).css({
      left: boxwidth * start + "%",
      width: boxwidth * days + "%"
    });
  }
}

Vue.directive("p-chart-position", {
  inserted(el, binding) {
    const data = binding.value;
    // console.log(data);
    charbox.position(data, el);
  },
  update: (el, binding) => {
    const data = binding.value;
    charbox.position(data, el)
    // $(el).niceSelect("update");
  },
  componentUpdated: (el, binding) => {
    const data = binding.value;
    charbox.position(data, el)
    // $(el).niceSelect("update");
  }
});

const refreshfield = (el, binding) => {
  const mydate = moment(binding.value.mydate).clone().startOf("month");
  const endof = moment(binding.value.mydate).clone().endOf("month").add(10, "days");
  const endnumber = -mydate.diff(endof, "days");
  let itemdata = [];
  let sum = 1.5;
  const tmpvalue = binding.value.events.filter((item, keys) => {
    const diffnumber = -(mydate.diff(item.start, "days"));

    return diffnumber >= 0 && diffnumber < endnumber;
  });

  tmpvalue.map((item, keys) => {
    const thitem = {
      start: moment(item.start),
      end: moment(item.end),
      diffstart: -(mydate.diff(item.start, "days")),
      diffend: -(mydate.diff(item.end, "days")),
    };
    // console.log(thitem);
    let zIndex = 1;
    if (keys > 0) {

      itemdata.forEach((values, index) => {
        // console.log(values.order , zIndex,values.name,keys)
        if (zIndex == values.order) {
          const preitem = {
            start: moment(values.start),
            end: moment(values.end),
            diffstart: -(mydate.diff(values.start, "days")),
            diffend: -(mydate.diff(values.end, "days")),
          };

          if (!(thitem.diffend < preitem.diffstart || thitem.diffstart > preitem
              .diffend)) {
            zIndex = zIndex + 1.5;
            sum = sum + 0.5;
          }
        }
      })
    }
    $(el).find(".ComCalendar__chart").eq(keys).css("top", zIndex + "rem");
    itemdata.push({
      start: item.start,
      end: item.end,
      order: zIndex,
      StartMonth: item.StartMonth,
      EndMonth: item.EndMonth,
      startYear: item.startYear,
      endYear: item.endYear,
    });
  });

  let valuedata = [];
  itemdata.map((item, keys) => {

    if (keys > 0) {
      valuedata.map((values, index) => {
        if ((values.start == item.start) || (values.end == item.end) || (values.start <
            item.start && item.start < values.end) || (values.start < item.end && item
            .end < values.end)) {
          if (values.order == item.order) {
            item.order = item.order + 1.5;
          }
        }
      });
    }

    $(el).find(".ComCalendar__chart").eq(keys).css("top", item.order + "rem");

    valuedata.push({
      start: item.start,
      end: item.end,
      order: item.order,
      StartMonth: item.StartMonth,
      EndMonth: item.EndMonth,
      startYear: item.startYear,
      endYear: item.endYear,
    });
  });

  // console.log(valuedata);

  $(el).css({
    height: 2 * sum + "rem"
  });
  $(el).find(".col").css({
    height: 2 * sum + "rem"
  });
}

Vue.directive("p-chart-box", {
  inserted(el, binding) {
    // console.log(this);
    refreshfield(el, binding);
  },
  update: (el, binding) => {
    refreshfield(el, binding);
    // $(el).niceSelect("update");
  },
  componentUpdated: (el, binding) => {
    refreshfield(el, binding);
    // $(el).niceSelect("update");
  }
})