<template>
  <footer class='base--infooter'>
    <div class="container margin_60_35">
      <div class="row">
        <div class="col-lg-5 col-md-6 p-r-5 my-lg-3 my-2">
          <img src="@/assets/img/mageelogo.jpeg" class="mt-2 my-md-4"
            style="width:100px;height:100px;" data-retina="true" alt="magee logo">
          <img src="@/assets/img/MargeeQrcode.png" class="mt-2 my-md-4 ml-3" alt="magee qrcode"
            style="width:100px;height:100px">
          <span class="d-none d-sm-none d-md-block"
            style="margin-top:1rem">地址：臺北市大安區復興南路二段268號3樓</span>
          <div class="follow_us d-none d-sm-none d-md-block">
            <ul>
              <li>Follow us</li>
              <li><a href="https://www.facebook.com/mageecube/" target="_blank"><i
                    class="ti-facebook"></i></a></li>
              <li><a href="#0"><i class="ti-twitter-alt"></i></a></li>
              <li><a href="#0"><i class="ti-google"></i></a></li>
              <li><a href="#0"><i class="ti-pinterest"></i></a></li>
              <li><a href="#0"><i class="ti-instagram"></i></a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-2 ml-lg-auto mt-lg-1">
          <!-- <h5 class="d-none d-sm-none d-md-block dropdown-toggle mb-4"
            style="cursor: default; letter-spacing:2px" data-toggle="collapse" href="#collforabout"
            role="button" aria-expanded="false" aria-controls="collforabout">關於我們</h5>
          <div class="d-none d-sm-none d-md-block">
            <ul class="links">
              <li><a href="about.html">About</a></li>
              <li><a href="login.html">Login</a></li>
              <li><a href="register.html">Register</a></li>
              <li><a href="blog.html">最新消息</a></li>
              <li><a href="contacts.html">Contacts</a></li>
            </ul>
          </div> -->
          <!-- pc -->

          <!-- <h5 class="d-block d-sm-block d-md-none" v-b-toggle.collapse-1>
            關於我們
            <font-awesome-icon :icon="['fas', 'caret-down']" />
          </h5>
          <b-collapse id="collapse-1" class="mt-2">
            <ul class="links pb-1" style="margin:0">
              <li><a href="about.html"></a></li>
              <li><a href="login.html">Login</a></li>
              <li><a href="register.html">Register</a></li>
              <li><a href="blog.html">最新消息</a></li>
              <li><a href="contacts.html">Contacts</a></li>
            </ul>
          </b-collapse> -->
          <!-- phone -->
        </div>

        <div class="col-lg-3 col-md-4 mt-lg-1">
          <div>
            <h5 class="d-none d-sm-none d-md-block dropdown-toggle mb-4"
              style="cursor: default; letter-spacing:2px" data-toggle="collapse"
              href="#collforaddress" role="button" aria-expanded="false"
              aria-controls="collforaddress">聯絡我們</h5>
            <div class="d-none d-sm-none d-md-block">
              <ul class="contacts">
                <li><a href="tel://27338118"><i class="ti-mobile"></i> (02)-2733-8118</a></li>
                <li><a href="mailto:magee@magee.com"><i class="ti-email"></i> magee@magee.com</a>
                </li>
              </ul>
            </div>
            <!-- pc -->

            <h5 class="d-block d-sm-block d-md-none" v-b-toggle.collapse-2>
              聯絡我們
              <font-awesome-icon :icon="['fas', 'caret-down']" />
            </h5>
            <b-collapse id="collapse-2" class="mt-2">
              <ul class="contacts" style="margin:0">
                <li><a href="tel://27338118"><i class="ti-mobile"></i> (02)-2733-8118</a></li>
                <li><a href="mailto:magee@magee.com"><i class="ti-email"></i>
                    magee@magee.com</a>
                </li>
                <li><span style="margin-top:1rem">地址：臺北市大安區復興南路二段268號3樓</span></li>
                <li>
                  <div class="follow_us">
                    <ul>
                      <li>Follow us</li>
                      <li><a href="https://www.facebook.com/mageecube/" target="_blank"><i
                            class="ti-facebook"></i></a></li>
                      <li><a href="#0"><i class="ti-twitter-alt"></i></a></li>
                      <li><a href="#0"><i class="ti-google"></i></a></li>
                      <li><a href="#0"><i class="ti-pinterest"></i></a></li>
                      <li><a href="#0"><i class="ti-instagram"></i></a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </b-collapse>

            <!-- phone -->

          </div>
          <!-- <div class="pt-1 pt-md-0" id="newsletter">
            <h6>Newsletter</h6>
            <div id="message-newsletter"></div>
            <form action="#" name="newsletter_form" id="newsletter_form">
              <div class="form-group">
                <input type="email" name="email_newsletter" id="email_newsletter"
                  class="form-control" placeholder="Your email">
                <input type="submit" value="Submit" id="submit-newsletter">
                <button id="submit-newsletter">Submit</button>
              </div>
            </form>
          </div> -->
        </div>
      </div>
      <!--/row-->
      <hr style="margin:0.5rem 0">
      <!-- <div class="row">
        <div class="col-12" style="text-align:center;">
          <span>Copyright&copy; Magee Inc. All rights reserved</span>
        </div>
      </div> -->
      <div class="row">
        <div class="col-lg-6">
          <ul id="footer-selector">
            <li>
              <div class="styled-select" id="lang-selector">
                <select @change="changeLang($event)">
                  <option value="zh_tw" :selected="targetLang === 'zh_tw'">繁中</option>
                  <option value="English" :selected="targetLang === 'English'">English</option>
                </select>
              </div>
            </li>
            <li>
              <div class="styled-select" id="currency-selector">
                <select>
                  <option value="US Dollars" selected>US Dollars</option>
                  <option value="Euro">Euro</option>
                </select>
              </div>
            </li>
            <li><img src="@/assets/img/cards_all.svg" alt=""></li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul id="additional_links">
            <li><a href="#0">Terms and conditions</a></li>
            <li><a
                href="https://www.privacypolicies.com/privacy/view/acba0f387ebe51269367b3410daa2b7b">Privacy</a>
            </li>
            <li><span>&copy; 2019 MageeMatch</span></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <!--/footer-->
</template>


<script>
  import store from '@/store';
  let footer = {};
  export default {
    data() {
      footer = {
        img: {
          mageelogo: '@/assets/img/magee.png',
        },
        imgpath: process.env.VUE_APP_FILE,
        targetLang: localStorage.language?localStorage.language:'zh_tw',
      }
      return footer;
    },
    methods: {
      changeLang(event) {
        // console.log(event.target.value);
        localStorage.language = event.target.value;
        location.reload();
      }
    },
  }

</script>
