import Vue from 'vue';
import './plugins/axios'
import './plugins/fontawesome';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/style.scss';
import './assets/css/vendors.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import "./style/match.scss"; //匯入sass

import axios from 'axios';
import VueAxios from 'vue-axios';
import BootstrapVue from 'bootstrap-vue';

import VueCurrencyFilter from 'vue-currency-filter';
import './service/filter/publicfilter.js';
import './directive/styleelement.js';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import DateJsPicker from 'vuejs-datepicker'
import vSelect from 'vue-select';
import Password from 'vue-password-strength-meter';
// import GanttElastic from 'gantt-elastic';
import "card/dist/card.js";
import GAuth from "vue-google-oauth2";
import VuejsClipper from 'vuejs-clipper';
import VueRx from 'vue-rx';
import '@/assets/application.js'
import VueClipboard from 'vue-clipboard2'
import ToggleButton from 'vue-js-toggle-button'

Vue.config.productionTip = false
Vue.use(ToggleButton)

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);
Vue.component('v-select', vSelect);
Vue.component('Password', Password);
// Vue.component('GanttElastic', GanttElastic);
Vue.config.productionTip = false;

const gauthOption = {
  clientId: "163198978527-9do3b8pum3mmq8o85a93fth29fmqmbhe.apps.googleusercontent.com",
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
    clipperFixed: true
  }
});
Vue.use(VueRx);

Vue.use(GAuth, gauthOption);
Vue.use(BootstrapVue);
Vue.use(VueMoment, {
  moment,
});
moment.locale('zh-tw');
Vue.use(DatePicker);
Vue.use(DateJsPicker);
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
});

axios.defaults.headers.common['Authorization'] = "Bearer " + (localStorage.Authorization || "");
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';


axios.interceptors.request.use(function (config) {
  if (!store.state.Public.loading.isLoading) {
    store.commit("Public/addloading");
  }
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  if (!store.state.Public.loading.isLoading) {
    store.commit("Public/removeloading");
  }
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  store.commit("Public/removeloading");
  // 对响应数据做点什么
  return response;
}, function (error) {
  store.commit("Public/removeloading");
  // 对响应错误做点什么
  return Promise.reject(error);
});

if (localStorage.language) {
  store.commit("Public/lang", localStorage.language);
} else {
  store.commit("Public/lang", 'zh_tw');
}

// console.log(localStorage.language);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
