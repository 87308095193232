import bri from '@/service/Brigade';
import router from '@/router';
// import moment from 'moment-timezone';

const state = {
  prolist: [],
  count: 0,
  search: {},
  filterUpdateCase: [
    'position', 'companyData', 'isOverdue', 'isSave', 'sys_company', 'uid', 'updatatime',
    ' updatetime', 'createtime', 'belong_company', 'releasedate'
  ],
  onepro: {
    proinfo: {
      casename: "",
      isSave: false,
      tittle: "",
      category: [],
      areacheck: [],
      casecontent: "",
      startdate: "",
      enddate: "",
      totaldays: 0,
      departure: [],
      location: [],
      attraction: "",
      companyData: {
        name: "",
        eveluation: {
          star: [0, 0, 0, 0, 0],
          comment: [],
          canReadMore: false,
          isComment: false,
          name: "",
          totalCommentCount: 0,
        }
      },
      connect: "",
      phone: "",
      attention: "",
      releasedate: "",
      updatatime: "",
      createtime: "",
      updatetime: "",
    },
    position: [{
      name: "",
      language: [],
      licence: [],
      notice: "",
      link: {
        btntext: "",
        func: "",
        name: "",
        take_uid: "",
      }
    }],
    starList: [],
    Review: []
  },
  List: {
    count: 0,
    data: []
  },
  RecruitingList: [],
  AllCasesList: [],
  AcceptList: [],
  CacheCaseList: [],
  detailRecommendPersonList: [],
  showSavePersonList: [],
  getSinglePostionList: [],
  GetCPList: [],
  HistoryRecordTakerList: [],
  GetObjectionList: [],
  CaseNotNull: [
    'date', 'enddate', 'location', 'departure',
    'connect', 'phone', 'casename ', 'name', 'num', 'salary', 'promisemoney',
  ],
  caseSort: [
    // {
    //   name: '總覽',
    //   linkname: 'AllCases',
    //   sort: 'all',
    //   color: "info"
    // },
    {
      name: '暫存旅團',
      linkname: 'CacheCase',
      sort: 'Issue',
      color: "warning"
    }, {
      name: '徵才中',
      linkname: 'Recruiting',
      sort: 'wait',
      color: "warning"
    }, {
      name: '執行中',
      linkname: 'CarryOut',
      sort: 'carryOut',
      color: "primary"
    },
    {
      name: '徵才完畢',
      linkname: 'RecruitCompleted',
      sort: 'RecruitCompleted',
      color: "success"
    }, {
      name: '執行完畢',
      linkname: 'Finished',
      sort: 'finished',
      color: "secondary"
    }, {
      name: '結案',
      linkname: 'ClosingCase',
      sort: 'complete',
      color: "secondary"
    },
    // {
    //   name: '違約異議',
    //   linkname: 'Casediscuss',
    //   sort:'discuss',
    // },
  ]
};

// actions
const actions = {
  getpro({
    commit
  }, data = {
    search: {},
    page: 1
  }) {
    // console.log(data.search, data.page);
    let result = bri.showcase(data.search, data.page);
    result.then(function (data) {
      commit("setProList", data.data);
    });
    return result;
  },
  unLoginShowCase({
    commit
  }, data = {
    search: {},
    page: 1
  }) {
    // console.log(data.search, data.page);
    let result = bri.unLoginShowCase(data.search, data.page);
    result.then(function (data) {
      commit("setProList", data.data);
    });
    return result;
  },
  GetPushCaseMan({
    commit
  }, data = {
    search: {},
    page,
  }) {
    // console.log(data);
    let result = bri.GetPushCaseMan(data.search, data.page);
    result.then(function (data) {
      commit("setProList", data.data);
    });
    return result;
  },
  getfilter({
    commit
  }, data) {
    // console.log(data);
    const searchtype = data.searchtype;
    const result = bri.filterCase(data.search, data.page, searchtype);
    result.then(function (data) {
      commit("setProList", data.data);
    });
    return result;
  },
  setunderTake({
    dispatch
  }, data) {
    // console.log(data);
    let result = bri.undertake(data);
    result.then(res => {
        if (res.data.state) {

          dispatch("Public/addAlert", {
            type: "success",
            name: "已送出應徵職位－" + res.data.result.name
          }, {
            root: true
          });
          // router.push({
          //   name: 'AllTakes',
          //   query: {
          //     status: 'all'
          //   }
          // });
        }
      })
      .catch(err => console.log(err));
    return result;
  },
  getonePro({
    commit
  }, data) {
    // console.log(data);
    const result = bri.prodetail({
      data: data
    });
    result.then(function (data) {
      commit("setOnePro", data.data);
    });
    // commit();
    return result;
  },
  savecase({
    commit
  }, uid) {
    const result = bri.savecase(uid)
    result.then(function (data) {
      // console.log(data);
      // commit("savecase", data.data);
    });
    // commit();
    return result;
  },
  recommendCase({
    commit
  }, data) {
    const result = bri.recommendCase(data.startdate, data.enddate, data.gap);
    result.then((data) => {
      commit("setProList", data.data);
    });
    return result;
  },
  showSaveCase({
    commit
  }, data) {

    const result = bri.showSaveCase(data.search, data.page);
    result.then((data) => {
      commit("setProList", data.data);
    });
    return result;
  },
  getRecruiting({
    commit
  }, data) {
    console.log(data);
    let result;
    result = bri.recruiting(data.page, data.search);
    result.then(function (data) {
      commit("RecruitingSet", data.data)
    })
    return result;
  },
  getAllCases({
    commit
  }, data) {
    console.log(data);
    let result;
    result = bri.AllCases(data.page, data.search, data.orderBy);
    result.then(function (data) {
      commit("AllCasesSet", data.data.result);
    })
    return result;
  },
  showCacheCase({
    commit
  }, data) {
    const result = bri.showCacheCase(data.page);
    result.then(function (data) {
      commit("CacheCaseSet", data.data)
    });
    return result;
  },
  Resume({
    commit,
    dispatch
  }, data) {
    let result;
    result = bri.Resume(data);
    const answer = data.answer;
    result.then(function (data) {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: answer == "refuse" ? "拒絕" : "接受"
        }, {
          root: true
        })
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: "接受失敗，請確認名額"
        }, {
          root: true
        })
      }
      commit("AcceptSet", data.data.result)
    })
    return result;
  },
  addcase({
    state,
    dispatch,
  }, data) {
    let result;
    let count = 0;
    $.map(data, function (value, index) {
      if ((state.CaseNotNull.indexOf(index) !== -1) && ((!value) || (value.length === 0))) {
        count++
      }
    });
    if (count !== 0) {
      dispatch("Public/addAlert", {
        type: "danger",
        "name": "必填欄位不得為空"
      }, {
        root: true
      });
    } else {
      result = bri.addcase(data).then((res) => {
        if (res.data.state) {
          router.push({
            name: 'AllCases',
            query: {
              status: 'wait'
            }
          });
        } else {
          dispatch("Public/addAlert", {
            type: "danger",
            "name": "必填欄位不得為空"
          }, {
            root: true
          });
        }
      });
    }

    return result;
  },
  removeCase({
    dispatch
  }, uid) {
    let result = bri.removeCase(uid);
    result.then((res) => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          "name": "刪除成功"
        }, {
          root: true
        });
        router.push({
          name: 'AllCases',
          query: {
            status: 'all'
          }
        })
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          "name": "已有人接團，不得刪除"
        }, {
          root: true
        });
      }
    });
    return result;
  },
  cacheCase({
    state,
    dispatch,
  }, data) {
    let result;
    let newData;

    if (!data.casename) {
      dispatch("Public/addAlert", {
        type: "danger",
        "name": "暫存旅團，旅團名稱不得為空"
      }, {
        root: true
      });
    } else {
      result = bri.cacheCase(data).then((res) => {
        console.log(res);
        if (res.data.state) {
          router.push({
            name: 'AllCases',
            query: {
              status: 'Issue'
            },
          });
        } else {
          dispatch("Public/addAlert", {
            type: "danger",
            "name": "必填欄位不得為空"
          }, {
            root: true
          });
        }
      });
    }

    return result;
  },
  caseDefault({}, data) {
    const result = bri.caseDefault(data);
    return result;
  },
  caseObjection({}, data) {
    const result = bri.caseObjection(data);
    return result;
  },
  GetObjectionList({
    commit
  }, data) {
    const result = bri.GetObjectionList(data.page);
    result.then((data) => {
      commit("GetObjectionSet", data.data)
    });
    return result;
  },
  checkInvite({
    commit
  }, data) {
    const result = bri.checkInvite(data);
    result.then((response) => {
      if (response.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "確認成功，將為您通知對方"
        }, {
          root: true
        })
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: "確認失敗"
        }, {
          root: true
        })
      }
    });
    return result;
  },
  cancelInvite({
    commit,
    dispatch
  }, data) {
    const result = bri.cancelInvite(data);
    result.then((response) => {
      if (response.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "取消成功"
        }, {
          root: true
        })
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: "取消失敗，請確認名額"
        }, {
          root: true
        })
      }
    })
    return result;
  },
  completeCase({}, data) {
    const result = bri.completeCase(data.case_uid);
    return result;
  },
  detailRecommendPerson({
    commit
  }, data) {
    const result = bri.detailRecommendPerson(data);
    result.then(function (data) {
      commit("detailRecommendPersonSet", data.data)
    });
    return result;
  },
  getSinglePostion({
    commit
  }, data) {
    const result = bri.getSinglePostion(data.position_uid);
    result.then(function (data) {
      commit("getSinglePostionSet", data.data)
    });
    return result;
  },
  GetCP({
    commit
  }, data) {
    const result = bri.GetCP(data.uid);
    result.then((data) => {
      commit("GetCPSet", data.data)
    }, () => {
      router.push({
        name: "Personinfo"
      })
    });
    return result;
  },
  addposition({
    commit
  }, data) {
    const result = bri.addposition(data.data);
    return result;
  },
  removePosition({
    commit
  }, data) {
    const result = bri.removePosition(data.data);
    return result;
  },
  issueCase({
    dispatch
  }, uid) {
    const result = bri.issueCase(uid);
    result.then((res) => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          "name": "發布成功"
        }, {
          root: true
        });
        router.push({
          name: 'AllCases',
          query: {
            status: 'wait'
          }
        })
      }
    });
    return result;
  },
  updatecase({
    commit,
    state,
    dispatch
  }, data) {
    console.log(data);
    Object.keys(data.data).map(item => {
      if (state.filterUpdateCase.indexOf(item) > -1) {
        delete data.data[item];
      }
    });
    const result = bri.updatecase(data);
    result.then(res => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          "name": "編輯成功"
        }, {
          root: true
        });
        if (!data.norouter) {
          router.push({
            name: 'AllCases',
            query: {
              status: 'all'
            }
          });
        }
      }
    })
    return result;
  },
  ShowHistoryRecordTaker({
    commit
  }, data) {
    // console.log(data);
    const result = bri.ShowHistoryRecordTaker(data);
    result.then(function (data) {
      commit("HistoryRecordTakerSet", data.data)
    });
    return result;
  },
  editPosition({}, data) {
    // console.log(data);
    const result = bri.editPosition(data);
    return result;
  },
  responseDiscuss({
    dispatch
  }, data) {
    const result = bri.responseDiscuss(data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("user/checkUser", {}, {
          root: true
        })
      }
    })
    return result;
  }

};

// mutations
const mutations = {
  GetObjectionSet(state, data) {
    state.List.data = data.result;
    state.List.count = data.count;
  },
  HistoryRecordTakerSet(state, data) {
    state.HistoryRecordTakerList = data.result;
  },
  GetCPSet(state, data) {
    state.GetCPList = data.result;
  },
  getSinglePostionSet(state, data) {
    state.getSinglePostionList = data.result;
  },
  showSavePersonSet(state, data) {
    state.showSavePersonList = data.result;
  },
  detailRecommendPersonSet(state, data) {
    state.detailRecommendPersonList = data.result;
  },
  setProList(state, data, search) {
    state.prolist = data.result;
    state.count = data.count;
    state.search = search;
  },
  setOnePro(state, data) {
    let proinfo = data.result.cases;
    state.onepro.proinfo = proinfo;
    state.onepro.position = data.result.position;
  },
  savecase(state, data) {
    state.onepro.proinfo.isSave = !!data.result.isopen;
  },
  Prolistsavecase(state, data) {
    const index = data.index;
    const isSave = data.data;
    state.prolist[index].isSave = !!isSave;
  },
  RecruitingSet(state, data) {
    state.List.data = data.result;
    state.List.count = data.count;
  },
  AllCasesSet(state, data) {
    state.List.data = data.data;
    state.List.count = data.count;
  },
  AcceptSet(state, data) {
    state.AcceptList = data;
  },
  CacheCaseSet(state, data) {
    state.List.data = data.result;
    state.List.count = data.count;
  },

}
// getters
const getters = {

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}