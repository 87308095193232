import memberapi from "@/service/member";
import CryptoJS from "crypto-js";
import base64 from "crypto-js/enc-base64";
import router from '@/router';

let state = {
  login: false,
  img: "",
  social_img: "",
  membername: "",
  memberUID: "",
  memberPhone: "",
  api_token: "",
  isperson: false,
  iscase: false,
  method: "",
  money: 0,
  email: "",
  resume: [],
  root: false,
  level: [],
  loginopen: false,
  company: {
    case: []
  },
  info: {},
  companyname: "",
  PointTransactionRecordList: [],
  noticeList: [],
  countNotice: 0,
  MemberMenuCount: {},
  forgetPwdOpen: {
    email: "",
    sendMail: false,
    verification: false,
    setPwd: false,
  },
};
const encrypt = (text) => {
  var key = base64.parse(process.env.VUE_APP_KEY); //为了避免补位，直接用16位的秘钥
  var iv = CryptoJS.enc.Latin1.parse("1234567890123456"); //16位初始向量
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  return btoa(JSON.stringify({
    iv: 1234567890123456,
    value: encrypted.toString(),
    mac: CryptoJS.SHA512(iv).toString()
  }));
};
// getters

// actions
const actions = {
  againsendcheckmail({
    commit,
    dispatch
  }, sort) {
    const result = memberapi.againsendcheckmail(sort);
    return result;
  },


  ///////////////////////////////////////
  /////////////////Login/////////////////
  login({
    commit,
    dispatch,
    rootState
  }, response) {
    let funResult, result;
    switch (response.provider) {
      case 'google':
        funResult = dispatch('googleLongin', response);
        break;
      case 'facebook':
        funResult = dispatch('facebookLogin', response);
        break;
      default:
        funResult = dispatch('localLogin', response);
    }

    funResult.then((res) => {
      result = memberapi.login(res.method, res.method, res.social, res.data);
      result.then((data) => {
        if (data.data.state) {
          localStorage.Authorization = data.data.result.api_token;
          axios.defaults.headers.common["Authorization"] = "Bearer " + (localStorage
            .Authorization || "");

          commit("checklogin", data.data.result);
          dispatch("Public/addAlert", {
            type: "success",
            "name": "登入成功"
          }, {
            root: true
          });
          commit("loginopen", false);
          dispatch("Public/setbaseMenu",{}, {
            root: true
          });
        } else if (data.data.result.msg === 'have no social account') {
          dispatch("Public/addAlert", {
            type: "danger",
            "name": "登入失敗，此社群帳戶尚未註冊"
          }, {
            root: true
          });
          router.push({
            name: 'SignIn'
          });
        } else {
          dispatch("Public/addAlert", {
            type: "danger",
            "name": "登入失敗，帳號或密碼錯誤"
          }, {
            root: true
          });
        }
      });
    });
    return funResult;
  },
  localLogin({}, data) {
    let generalResult;
    generalResult = {
      companycode: data.companycode,
      method: data.method,
      social: 'local',
      data: {
        email: data.email,
        password: data.password,
      }
    };
    return generalResult;
  },
  googleLongin({}, data) {
    const vm = this;
    let GoogleResult;
    return new Promise(function (resolve, reject) {
      vm._vm.$gAuth.signIn().then((res) => {
        GoogleResult = {
          companycode: data.companycode,
          method: data.method,
          data: res.Zi.access_token,
          social: 'google',
        }
        resolve(GoogleResult);
      });
    });

  },
  facebookLogin({}, data) {
    const vm = this;
    let facebookResult
    return new Promise(function (resolve, reject) {
      FB.login(function (res) {
        facebookResult = {
          companycode: data.companycode,
          method: data.method,
          data: res.authResponse.accessToken,
          social: 'facebook',
        }
        resolve(facebookResult);
      })

    });
  },
  /////////////////Login/////////////////
  ///////////////////////////////////////



  api_tokencheck: ({
    dispatch,
    commit
  }, data) => {
    localStorage.Authorization = data.api_token;
    axios.defaults.headers.common['Authorization'] = "Bearer " + (localStorage.Authorization ||
      "");
    commit("checklogin", data);
  },
  changepwd({
    commit
  }, data) {
    const old = encrypt(data.old);
    const response = encrypt(JSON.stringify({
      old: data.old,
      newpwd: data.newpwd
    }));
    const result = memberapi.changepwd(response);
    result.then(function (data) {


    });
    return result;
  },
  checkUser({
    commit,
    dispatch
  }) {
    const result = memberapi.checkUser();
    result.then((data) => {
      if (data.data.state == false) {
        commit("logout");
      } else {
        commit("checklogin", data.data.result);
        dispatch("Public/setbaseMenu", {},{
          root: true
        });
      }
    });
    return result;
  },
  register({
    commit,
    dispatch,
    state,
    rootState
  }, data) {
    let count = 0;
    // console.log(data);
    $.map(["name", "email"], function (index, value) {
      if (!index) {
        count++;
      }
    })
    if (count > 0) {
      dispatch("Public/addAlert", {
        type: "danger",
        name: "請全部填寫完畢"
      }, {
        root: true
      });
      return
    };
    if (data.correctpassword !== data.password) {
      dispatch("Public/addAlert", {
        type: "danger",
        name: "兩組密碼不相同"
      }, {
        root: true
      });
    } else {
      const response = encrypt(JSON.stringify(data));
      const result = memberapi.register(response);
      result.then((data) => {
        let msg = {
          type: "success",
          name: rootState.Public.publang.alertbox.resign.success
        }

        if (data.data.state) {
          dispatch("Public/addAlert", msg, {
            root: true
          });
          console.log(data);
          // const api_token = data.data.result.api_token;
          dispatch("api_tokencheck", data.data.result);
          router.push({
            name: "checkemail",
            query: {
              sort: data.data.result.method
            }
          });
        } else {
          msg = {
            type: "danger",
            name: "帳號重複"
          };
          dispatch("Public/addAlert", msg, {
            root: true
          });
        }
      });
      return result;
    }
  },
  verification({
    commit,
    dispatch,
    rootState
  }, number) {
    const result = memberapi.verification(number);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "信箱認證完成"
        }, {
          root: true
        });
        commit("checklogin", data.data.result);

        router.replace({
          name: 'Personinfo'
        });
      } else {

        dispatch("Public/addAlert", {
          type: "danger",
          name: rootState.Public.publang.alertbox[data.data.result]
        }, {
          root: true
        })
      }
    })
    return result;
  },

  //====================忘記密碼====================

  forgotToSendMail({
    dispatch,
    commit
  }, data) {
    if (!data.email) {
      dispatch("Public/addAlert", {
        type: "danger",
        name: '信箱不能空白'
      }, {
        root: true
      });
      return;
    }
    const result = memberapi.forgotToSendMail(data);
    result
      .then(res => {
        if (res.data.state) {
          commit("setForgetPwd", {
            modal: 'sendMail',
            isopen: false
          });
          commit("setForgetPwd", {
            modal: 'verification',
            isopen: true
          });
          commit("setForgetPwdAtEmail", data.email);
        }
      })
      .catch(err => console.log(err));
    return result;
  },

  verificatCode({
    dispatch,
    commit
  }, data) {
    if (!data.code) {
      dispatch("Public/addAlert", {
        type: "danger",
        name: '請輸入驗證碼'
      }, {
        root: true
      });
    } else {
      let result = memberapi.forgotToVerification(data);
      result
        .then(res => {
          if (res.data.state) {
            commit("setForgetPwd", {
              modal: 'verification',
              isopen: false
            });
            commit("setForgetPwd", {
              modal: 'setPwd',
              isopen: true
            });
          }
        })
        .catch(err => console.log(err));
      return result;
    }
  },

  resetPwd({
    dispatch,
    commit
  }, data) {
    if (data.password !== data.confirmPassword) {
      dispatch("Public/addAlert", {
        type: "danger",
        name: '兩組密碼不相同'
      }, {
        root: true
      });
    } else {
      const response = encrypt(JSON.stringify(data));
      let result = memberapi.resetPwd(response);
      result
        .then(res => {
          if (res.data.state) {
            dispatch("Public/addAlert", {
              type: "success",
              name: '修改密碼成功，請重新登入'
            }, {
              root: true
            });

            commit("setForgetPwd", {
              modal: 'setPwd',
              isopen: false
            });
            commit("loginopen", true);
          }
        })
        .catch(err => console.log(err));
      return result;
    }


  },

  //====================忘記密碼====================


  logout({
    commit
  }) {
    return new Promise((resolve) => {
      commit("reDefault");
      resolve();
    });
  },
  getresume({
    commit
  }) {
    const result = memberapi.showresume();
    result.then((data) => {
      if (data.data.status) {
        commit("setresume", data.data.result);
      }
    });
    return result;
  },
  getCompanyCase: ({
    commit
  }) => {
    const result = memberapi.pushcase();
    result.then(function (data) {
      commit("setcompanycase", data.data.result);
    });
    return result;
  },
  getuserinfo({
    commit
  }) {
    const result = memberapi.getuserinfo();
    result.then((data) => {
      commit("setuserinfo", data.data);
    });
    return result;
  },
  getCompanyInfo: ({
    commit
  }) => {
    const result = memberapi.getCompanyInfo();
    result.then((data) => {
      commit("setuserinfo", data.data);
    });
    return result;
  },
  updateuserinfo: ({
    dispatch
  }, data) => {
    const result = memberapi.updateuserinfo(data.info, data.file);
    result.then(result => {
        dispatch("Public/addAlert", {
          type: "success",
          name: "更新成功"
        }, {
          root: true
        });

        dispatch("getuserinfo");
      })
      .catch(err => {
        console.log(err)
      });
    return result;
  },
  updateCompanyInfo: ({
    commit
  }, data) => {
    const result = memberapi.updateCompanyInfo(data.info, data.file);
    return result;
  },
  addCard({
    commit
  }, data) {
    // console.log(data);
    const result = memberapi.addCard(data);
    return result;
  },
  addValue({
    commit
  }, data) {
    // console.log(data);
    const result = memberapi.addValue(data);
    return result;
  },
  PointTransactionRecord({
    commit
  }, data) {
    const result = memberapi.PointTransactionRecord();
    result.then((data) => {
      commit("PointTransactionRecordSet", data.data);
    });
    return result;
  },
  getNotice({
    commit
  }, data) {
    let result;
    result = memberapi.showDetailNotice(data);
    result.then(function (data) {
      commit("noticeSet", data.data)
    })
    return result;
  },
  countNotice({
    commit
  }, data) {
    let result;
    result = memberapi.countNotice();
    result.then(function (data) {
      commit("countNotice", data.data)
    })
    return result;
  },
  AllReadNotice({}, data) {
    let result;
    result = memberapi.AllReadNotice();
    return result;
  },
  readNotice({}, data) {
    // console.log(data);
    let result;
    result = memberapi.readNotice(data);
    return result;
  },
  authority({}, data) {
    // console.log(data);
    let result;
    result = memberapi.authority(data.info, data.sort, data.file);
    return result;
  },
  getMemberMenuCount({
    commit
  }, ) {
    // console.log(data);
    let result;
    result = memberapi.getMemberMenuCount();
    result.then((data) => {
      commit("MemberMenuCountSet", data.data)
    })
    return result;
  },
  socialBinding({
    dispatch
  }, data) {
    let result;
    if (data['provider'] === 'facebook') {

      result = dispatch('facebookBinding', data['sort']);

    } else if (data['provider'] === 'google') {

      result = dispatch('googleBinding', data['sort']);

    } else if (data['provider'] === 'weixin') {

      result = dispatch('weixinBinding', data['sort']);

    } else if (data['provider'] === 'line') {

      result = dispatch('flineBinding', data['sort']);

    }

    // console.log(data);
    // const result = memberapi.socialBinding(data);

    return result;
  },
  facebookBinding({
    dispatch
  }, sort) {
    let data;
    if (sort === 'signUp') {
      return new Promise((resolve, reject) => {
        FB.login(function (res) {
          data = {
            provide: "facebook",
            data: {
              access: res.authResponse.accessToken,
              sort: "",
            },
          };
          const result = memberapi.socialBinding(data);
          resolve(result);
        })
      });
    } else {

      FB.login(function (res) {
        data = {
          provide: "facebook",
          data: {
            access: res.authResponse.accessToken,
            sort: sort
          },
        };

        const result = memberapi.socialBinding(data);

        dispatch("getuserinfo");
        dispatch("Public/addAlert", {
          type: "success",
          "name": "綁定成功"
        }, {
          root: true
        });
      });

    }

  },
  googleBinding({
    dispatch,
    commit
  }, sort) {
    if (sort === 'signUp') {

      return new Promise((resolve, reject) => {
        resolve(this._vm.$gAuth.signIn());
      });

      // this._vm.$gAuth.signIn()
      //   .then((res) => {
      //     commit('setGoogleData', res);
      //   });

    } else {
      let data;
      const result = this._vm.$gAuth.signIn();
      result.then(function (res) {
        data = {
          provide: "google",
          data: {
            access: res,
            sort: sort
          },
        };
        memberapi.socialBinding(data);
        dispatch("getuserinfo");
        dispatch("Public/addAlert", {
          type: "success",
          "name": "綁定成功"
        }, {
          root: true
        });
      });
    }


  },
  weixinBinding({}, sort) {

  },
  flineBinding({}, sort) {

  },
  //////// 社群綁定

};

// mutations
const mutations = {
  checklogin(state, data) {
    // 变更状态
    // state.login = true;
    // method: data.data.result.method,
    //   img: data.data.result.img,
    //   social_img: data.data.result.social_img,
    //   membername: data.data.result.username,
    //   memberUID: data.data.result.uid,
    //   memberPhone: data.data.result.phone,
    //   api_token: data.data.result.api_token,
    //   money: data.data.result.money,
    //   person: data.data.result.person,
    //   iscase: data.data.result.iscase,
    //   send_email_check: data.data.result.send_email_check,
    //   email: data.data.result.email,
    //   root:data.data.result.root
    state.login = true;
    state.img = data.img;
    state.method = data.method;
    // state.social_img = data.social_img;
    state.membername = data.username;
    state.memberUID = data.uid;
    state.memberPhone = data.phone;
    state.api_token = data.api_token;
    state.money = data.money;
    state.isperson = data.person;
    state.iscase = data.iscase;
    state.email = data.email;
    state.root = data.root;
    state.level = data.level;
    if (data.infoname) {
      state.companyname = data.infoname;
    }
    // console.log(state, data);
  },
  logout(state) {
    state.login = false;
    state.membername = "";
    state.api_token = "";
    state.iscase = false;
    state.isperson = false;
  },
  reDefault(state) {
    state.login = false;
    state.membername = "";
    state.api_token = "";
    state.money = 0;
    localStorage.Authorization = "";
    axios.defaults.headers.common['Authorization'] = "Bearer ";
    // console.log(localStorage);
  },
  setresume(state, data) {
    state.resume = data;
  },
  setcompanycase(state, data) {
    state.company.case = data;
  },
  setuserinfo(state, data) {
    $.map(data.result, (item, keys) => {
      state.info[keys] = item;
    });
  },
  PointTransactionRecordSet(state, data) {
    state.PointTransactionRecordList = data.result
  },
  noticeSet(state, data) {
    state.noticeList = data.result;
  },
  countNotice(state, data) {
    state.countNotice = data.result;
  },
  MemberMenuCountSet(state, data) {
    state.MemberMenuCount = data.result;
  },
  setGoogleData(state, data) {
    state.socialData.provider = 'google';
    state.socialData.id = data.w3.Eea;
    state.socialData.name = data.w3.ig;
    state.socialData.email = data.w3.U3;
    state.socialData.img = data.w3.Paa;
  },
  setFacebookData(state, data) {
    state.socialData.provider = 'facebook';
    state.socialData.id = data.w3.Eea;
    state.socialData.name = data.name;
    state.socialData.email = data.email;
    state.socialData.img = data.avatar;
  },
  signUpEmail(state, data) {
    state.socialData.email = data;
  },
  signUpName(state, data) {
    state.socialData.name = data;
  },
  loginopen(state, data) {
    state.loginopen = data;
  },
  setForgetPwd(state, data) {
    state.forgetPwdOpen[data.modal] = data.isopen;
  },
  setForgetPwdAtEmail(state, data) {
    // console.log(data);
    state.forgetPwdOpen.email = data;
  }
};
const getters = {

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}