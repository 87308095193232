import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app'
import user from './modules/user'
import getters from './getters'
import Talent from './modules/Talent';
import Brigade from './modules/Brigade';
import Public from './modules/Public';
import backstage from './modules/backstage';
import Company from './modules/Company';
import Group from './modules/Group';
import VuexPersistence from 'vuex-persist';
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    route: state.route,
    lastLaunch: new Date().valueOf(),
  }),
});

export default new Vuex.Store({
  modules: {
    app,
    user,
    Talent,
    Public,
    Brigade,
    backstage,
    Company,
    Group
  },
  getters,
  plugins: [vuexLocal.plugin],
});