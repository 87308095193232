<template>
  <div id="app">
    <!-- <HeaderInfo />

    <SectionSearch /> -->
    <router-view class="view one" name="header" />
    <main id="page-wrap">
      <!-- <router-view class="view two" name="sectionSearch" /> -->
      <router-view class="view three" name="mainbody" />
    </main>
    <b-toast id="my-toast" :variant="alertbox.type" solid v-for='alertbox in pub.alertbox' toaster='b-toaster-top-center' :visible='true'>
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <!-- <b-img blank :class='["bg-"+alertbox.type]' class="mr-2" width="12" height="12"></b-img> -->
        <strong class="mr-auto">{{alertbox.title || alertbox.type}}</strong>
      </div>
      {{alertbox.name | changelang('waring')}}
    </b-toast>
    <!-- <div class="fixed-bottom pr-3 float-right col-lg-4 col-sm-12 offset-lg-8 best-u">
      <b-alert :show='true' fade class='' :variant="alertbox.type" v-for='alertbox in pub.alertbox'>
        {{alertbox.name | changelang('waring')}}
      </b-alert>
    </div> -->

    <div class="vld-parent">
      <loading :active.sync="pub.loading.isLoading" :is-full-page="pub.loading.fullPage"></loading>
    </div>

    <router-view name="footer" />
    <!-- <Sectionfooter /> -->
    <div id="toTop" v-toTop></div>
  </div>
</template>

<script>
  // import HeaderInfo from '@/components/HeaderInfo.vue';
  // import SectionSearch from '@/components/SectionSearch.vue';
  import Vue from 'vue';
  import Loading from 'vue-loading-overlay';
  import store from "@/store";
  import Sectionfooter from '@/components/Sectionfooter.vue';

  Vue.use(Loading);
  let vm = "";
  var notifyConfig = {
    body: "\\ ^o^ /",
    icon: "https://cythilya.github.io/public/favicon.ico"
  }

  // function createNotify() {
  //   console.log(window.Notification);
  //   if (!("Notification" in window)) { // 檢查瀏覽器是否支援通知
  //     console.log("This browser does not support notification");
  //   } else if (Notification.permission === "granted") { // 使用者已同意通知
  //     var notification = new Notification(
  //       "Thanks for granting this permission.", notifyConfig
  //     );
  //   } else if (Notification.permission !== "denied") {
  //     // 通知權限為 default (未要求) or undefined (老舊瀏覽器的未知狀態)，向使用者要求權限
  //     Notification.requestPermission(function (permission) {
  //       if (permission === "granted") {
  //         var notification = new Notification("Hi there!", notifyConfig);
  //       }
  //     });
  //   }
  // }

  // function sendNotify(msg) {
  //   var notify = new Notification(msg.title, {
  //     icon: msg.icon,
  //     body: msg.body,
  //     tag: msg.tag,
  //   });

  //   if (msg.url) {
  //     notify.onclick = function (e) { // Case 3: 綁定 click 事件
  //       e.preventDefault();
  //       window.open(msg.url);
  //     }
  //   }
  // }

  // function notifyMe() {
  //   var tagList = ['newArrival', 'newFeature', 'newMsg', 'promotion'];
  //   var len = tagList.length;
  //   var times = 0;
  //   var timerNewArrival = setInterval(function () {
  //     sendNotify({
  //       title: tagList[times % len] + ' ' + times,
  //       body: '\\ ^o^ /',
  //       tag: tagList[times % len],
  //       icon: "https://cythilya.github.io/public/favicon.ico",
  //       url: 'http://www.ruten.com.tw/'
  //     });
  //     if (times++ == 20) {
  //       clearInterval(timerNewArrival);
  //     }
  //   }, 1000);
  // }



  export default {
    methods: {
      countDownChanged(dismissCountDown) {
        // console.log(dismissCountDown);
        this.dismissCountDown = dismissCountDown
      },

    },
    data() {
      vm = this;
      let loaded;
      // console.log("a");
      // axios.interceptors.request.use((config) => {
      //   loaded = this.$loading.show();
      //   // Loading.show();
      //   return config;
      // }, (error) => {
      //   alert("看到這個請幫我拍照下來，跟我說聲操作流程");
      //   this.$loading.hide();
      //   return Promise.reject(error);
      // });

      // axios.interceptors.response.use((response) => {
      //   loaded.hide();
      //   return response
      // }, (error) => {
      //   loaded.hide();
      //   return Promise.reject(error);
      // });
      return {
        showmenu: process.env.VUE_APP_SHOWMENU,
        URL: document.URL,
        pub: store.state.Public
      };
    },

    components: {
      Sectionfooter,
      Loading,
    },
    mounted: () => {
      // createNotify();
      // notifyMe();
      window.fbAsyncInit = function () {
        FB.init({
          appId: process.env.VUE_APP_FACEBOOKCODE,
          cookie: true,
          xfbml: true,
          version: 'v2.9'
        });
        FB.AppEvents.logPageView();
      };
    }
  }
</script>
<style lang="scss">

</style>