import company from '@/service/Company';

const state = {}

const actions = {
  Resign({
    commit,
    state,
    dispatch
  }, data) {
    const result = company.Resign(data);
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "已通知管理人員，人員會盡快為您確認身份"
        }, {
          root: true
        });
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: data.data.result.msg
        }, {
          root: true
        });
      }
    });
  },
  getAccount(){
    const result = company.getAccount();
    return result;
  },
  getchildmember(){
    const result = company.getchildmember();
    return result;
  },
  changeAccountData({rootState,commit},data){
    const result = company.changeAccountData(data);
    return result;
  },
  insertUser({},data){
  
    const result = company.insertUser(data);
    return result;
  },
  deleteUid({},data){
    const result = company.deleteUid(data);
    return result;
  },
  changepassword({},data){
    const result = company.changepassword(data);
    return result;
  },
  allCase({},data){
    console.log(data);
    const result = company.CompanyAllCase(data.orderby,data.page,data.search);
    return result;
  },
  getCompanyGroup({},data){
    const result = company.getCompanyGroup(data.orderby,data.page,data.search,data.method);
    return result;
  }
  
}

const mutations = {}

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}