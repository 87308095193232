import pbAx from '@/service/public';
import zh_tw from '@/lang/zh_tw';

import moment from "moment";

let state = {
  country: {
    data: [],
    catch: moment()
  },
  Category: {
    data: [],
    catch: moment()
  },
  licenceName: {
    data: [],
    catch: moment()
  },
  street: {
    data: [],
    catch: moment()
  },
  caseCode: {
    data: [],
    catch: moment()
  },
  language: {
    data: {},
    catch: moment()
  },
  airportList: [],
  portList: [],
  position: {
    data: [],
    catch: moment()
  },
  alertbox: [],
  loading: {
    fullPage: true,
    isLoading: false,
  },
  transactionList: {
    data: {},
    count: 0
  },
  hotCaseList: [{
    uid: "",
    tittle: "",
    casename: "",
    casecontent: "",
    isSave: false,
    startdate: "",
    enddate: "",
    createtime: "",
    updatetime: "",
    updatatime: "",
    Expecte: 1,
  }],
  hotPersonList: [{
    img: "",
    username: "",
    user_uid: "",
    updatetime: "",
    updatatime: "",
    starAvg: "",
    skill: "",
    licence: [],
    language: [],
    gender: "",
    expectSalary: 0,
    address: "",
    category: "",
  }],
  publang: {},
  transactionInfo: {},
  inviteModal: false,
  undertakeModal: false,
  languageAbility: [{
    text: '好',
    value: 'excellent'
  }, {
    text: '中等',
    value: 'good'
  }, {
    text: '差',
    value: 'little'
  }],
  bound: [{
      text: '國內旅遊',
      value: 'inbound'
    },
    {
      text: '國外旅遊',
      value: 'outbound'
    },
    {
      text: '來台旅遊',
      value: 'fromTW'
    },
  ],
  baseMenu: []
};

const checkshow = (rootState, method, users = "", root = false) => {
  // (method, users = "", root = false)
  const userlevel = rootState.user.level;
  let tstate = true;
  if (typeof (method) == "string") {
    tstate = userlevel.indexOf(method) > -1;
  } else {
    method.forEach((item, keys) => {
      if (userlevel.indexOf(item) == -1) {
        tstate = false;
      }
    });
  }
  if (users) {
    tstate = tstate && rootState.user.method;
  }
  if (root) {
    tstate = rootState.user.root;
  }
  return tstate;
};
// actions
const actions = {
  setbaseMenu({
    commit,
    dispatch,
    state,
    rootState
  }, data) {
    const basemember = [{
        title: "帳戶管理",
        show: checkshow(rootState, "person"),
        subshow: false,
        sub: [{
            name: "個人檔案",
            linkname: 'Personinfo',
            level: 'isperson',
            show: checkshow(rootState, "person")
            // hide:!this.checkshow(rootState, "person")
            //   active: routename == "Personinfo"
          }, {
            name: "行事曆",
            linkname: 'calendar',
            level: 'isperson',
            show: checkshow(rootState, "person")
            //   active: routename == "Personinfo"
          }, {
            name: "加值系統",
            linkname: 'deposit',
            level: 'isperson',
            show: checkshow(rootState, "person")
            //   active: routename == "Personinfo"
          }, {
            name: "收支列表",
            linkname: 'IncomeStatement',
            level: 'isperson',
            show: checkshow(rootState, "person")
            //   active: routename == "Personinfo"
          },
          {
            name: "修改密碼",
            linkname: 'changepassword',
            level: 'isperson',
            show: checkshow(rootState, "person")
            //   active: routename == "changepassword"
          },
          {
            name: "通知",
            linkname: 'Memberalertbox',
            level: 'isperson',
            show: checkshow(rootState, "person")
            //   active: routename == "Memberalertbox"
          },
          {
            name: "訊息",
            linkname: 'Membermessage',
            level: 'isperson',
            show: checkshow(rootState, "person")
          },
          {
            name: "黑名單公司",
            linkname: 'Memberblacklist',
            level: 'isperson',
            show: checkshow(rootState, "person")
            //   active: routename == "Memberblacklist"
          },
          {
            name: "申請功能",
            linkname: 'ApplicationIdentity',
            show: checkshow(rootState, "person")
            // level: 'isperson',
          }
        ],
      }, {
        title: "接案管裡",
        subshow: false,
        show: checkshow(rootState, "person"),
        sub: [{
          name: "接案總覽",
          linkname: 'AllTakes',
          level: 'isperson',
          sort: {
            status: 'all'
          },
          show: checkshow(rootState, "person")
        }, {
          name: "媒合中",
          linkname: 'AllTakes',
          level: 'isperson',
          sort: {
            status: ['accept', 'money', 'check']
          },
          show: checkshow(rootState, "person")
        }, {
          name: "媒合完成",
          linkname: 'AllTakes',
          level: 'isperson',
          sort: {
            status: ['confirm', 'carryOut']
          },
          show: checkshow(rootState, "person")
        }, {
          name: "執行完成",
          linkname: 'AllTakes',
          level: 'isperson',
          sort: {
            status: ['finished']
          },
          show: checkshow(rootState, "person")
        }, {
          name: "歷史接案",
          linkname: 'HistoryTakeCase',
          level: 'isperson',
          show: checkshow(rootState, "person")
        }, {
          name: "爭議中",
          linkname: 'TakerDiscuss',
          level: 'isperson',
          show: checkshow(rootState, "person")
        }]
      }, {
        title: "企業管理",
        show: checkshow(rootState, "company", "company"),
        subshow: false,
        sub: [{
            name: "企業簡介",
            linkname: 'CompanyProfile',
            level: 'iscase',
            show: checkshow(rootState, ["company", "person"], "company")
          },
          {
            name: "企業案子",
            linkname: 'CompanyCase',
            level: 'iscase',
            show: checkshow(rootState, ["company"], "company")
          },
          {
            name: "企業群組",
            linkname: 'Companygroup',
            linkother: ["groupMember", "groupCalendarManage"],
            level: 'iscase',
            fullpage: true,
            show: checkshow(rootState, ["group"], "company")
          },
          {
            name: "企業群組名單",
            linkname: 'GroupCompanyList',
            linkother: ["groupMember", "groupCalendarManage"],
            level: 'iscase',
            fullpage: false,
            show: checkshow(rootState, ["group"], "company", true)
          },
        ]
      }, {
        title: "發案管理",
        show: checkshow(rootState, "company"),
        subshow: false,
        sub: [{
          name: "新增專案",
          linkname: 'EditCase',
          params: {
            sourcepage: "AllCase",
            method: "add"
          },
          show: checkshow(rootState, "company")
        }, {
          name: "專案總覽",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'all'
          },
          show: checkshow(rootState, "company")
        }, {
          name: "暫存專案",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'Issue'
          },
          show: checkshow(rootState, "company")
        }, {
          name: "徵才中專案",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'wait'
          },
          show: checkshow(rootState, "company")
        }, {
          name: "徵才完畢專案",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'RecruitCompleted'
          },
          show: checkshow(rootState, "company")
        }, {
          name: "執行中專案",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'carryOut'
          },
          show: checkshow(rootState, "company")
        }, {
          name: "執行完畢專案",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'finished'
          },
          show: checkshow(rootState, "company")
        }, {
          name: "結案專案",
          linkname: 'AllCases',
          level: 'iscase',
          sort: {
            status: 'complete'
          },
          show: checkshow(rootState, "company")
        }]
      },
      //群組管理
      {
        title: "群組管理",
        show: checkshow(rootState, "group"),
        subshow: false,
        sub: [{
          name: "群組總覽",
          linkname: "mygroup",
          level: 'person',
          show: checkshow(rootState, "group")
        }]
      }
    ];
    commit("setBaseMenu", basemember);
  },
  getCountry({
    commit
  }) {
    let result;
    let difftime = moment().diff(state.country.catch, "minutes");
    if (state.country.data.length > 0 && difftime <= 10) {
      result = new Promise(
        () => {
          return {
            result: {
              "state": true,
              "catch": true,
              "result": state.country,
              // "catchtime":
            },
            state: true
          };
        });
    } else {
      result = pbAx.country();
      result.then(function (data) {
        if (data.data.state) {
          // console.log(data.data.result);
          commit("CountySet", data.data.result);
        }
      });
    }
    return result;
  },
  getstreet({
    commit
  }, country) {
    let result = pbAx.street(country);
    result.then(function (data) {
      if (data.data.state == "success") {
        commit("streetSet", data.data.result);
      }
    });
    return result;
  },
  getLanguage({
    commit
  }) {
    let result;
    let difftime = moment().diff(state.language.catch, "minutes");
    if (state.language.data.length > 0 && difftime <= 10) {
      result = new Promise(
        () => {
          return {
            result: {
              "state": true,
              "catch": true,
              "result": state.language.data,
              // "catchtime":
            },
            state: true
          };
        });
    } else {
      result = pbAx.publicClass("language_code");
      result.then(function (data) {
        if (data.data.state) {
          commit("languageSet", data.data.result);
        }
      });
    }
    return result;
  },
  getCategory({
    commit
  }) {
    let result;
    let difftime = moment().diff(state.Category.catch, "minutes");
    if (state.Category.data.length > 0 && difftime <= 10) {

      result = new Promise(
        () => {
          return {
            result: {
              "state": true,
              "catch": true,
              "result": state.Category.data,
              // "catchtime":
            },
            state: true
          };
        });
    } else {
      result = pbAx.Category();
      result.then(function (data) {
        if (data.data.state) {
          commit("CategorySet", data.data.result);
        }
      });
    }
    return result;
  },
  getCaseCodeSet({
    commit
  }) {
    let result;
    result = pbAx.publicClass("case_code");
    result.then(function (data) {
      if (data.data.state) {
        commit("CaseCodeSet", data.data.result);
      }
    });
    return result;
  },
  getlanguage() {
    const result = pbAx.publicClass("language_code");
    return result;
  },
  defultSetting({
    dispatch
  }) {
    let prom1 = dispatch("getCountry");
    let prom2 = dispatch("getCategory");
    let prom3 = dispatch("getCaseCodeSet");
    let prom4 = dispatch("getLanguage");
    let prom5 = dispatch("getPosition");
    let result = Promise.all([prom1, prom2, prom3, prom4, prom5]);
    result.then(values => {

    });
    return result;
  },
  getAirport({
    commit
  }) {
    let result = pbAx.getAirport();
    result.then(res => {
      commit("setAirport", res.data);
    });
    return result;
  },
  getPort({
    commit
  }) {
    let result = pbAx.getPort();
    result.then(res => {
      commit("setPort", res.data);
    });
    return result;
  },
  getPosition({
    commit
  }) {
    let result;
    result = pbAx.publicClass("position_code");
    result.then(function (data) {
      commit("positionSet", data.data.result)
    });
    return result;
  },
  addAlert({
    commit
  }, data) {
    commit("addalertbox", data);
    setTimeout(function () {
      commit("removealertbox", 0);
    }, 5000);
  },
  getEvaluation({
    commit
  }, data) {
    const result = pbAx.evaluation(data);
    return result;
  },
  getHotCase({
    commit
  }, data) {
    let result = pbAx.hotCase(data);
    result.then(function (data) {
      commit("hotCase", data.data);
    });
    return result;
  },
  getHotPerson({
    commit
  }, data) {
    let result = pbAx.hotPerson(data);
    result.then(function (data) {
      commit("hotPerson", data.data);
    });
    return result;
  },
  getlicenceCode({
    commit
  }, name) {

    const result = pbAx.licenceName(name);
    return result;
  },
  Evaluate({
    dispatch
  }, data) {
    const result = pbAx.Evaluate(data);
    result.then((res) => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          "name": "評論成功"
        }, {
          root: true
        });
      }
    });
    return result;
  },
  transaction({
    commit
  }, data) {
    // console.log(data);
    const result = pbAx.transaction(data.search, data.page, data.currentPage);
    result.then(function (data) {
      // console.log(data);
      commit("transactionSet", data.data.result);
    })
    return result;
  },
  transactionInfo({
    commit
  }, data) {
    // console.log(data);
    const result = pbAx.transactionInfo(data);
    result.then(function (data) {
      commit("transactionInfoSet", data.data);
    })
    return result;
  }
};

// mutations
const mutations = {
  setBaseMenu(state, data) {
    state.baseMenu = data;
  },
  transactionInfoSet(state, data) {
    state.transactionInfo = data.result;
  },
  transactionSet(state, data) {
    state.transactionList.data = data.data;
    state.transactionList.count = data.count;
  },
  CountySet(state, data) {

    state.country.data = data;
    state.country.catch = moment();
  },
  CategorySet(state, data) {

    state.Category.data = data;
    state.Category.catch = moment();
  },
  licenceNameSet(state, data) {

    state.licenceName.data = data;
    state.licenceName.catch = moment();
  },
  streetSet(state, data) {
    state.street.data = data;
    state.street.catch = moment();
  },
  CaseCodeSet(state, data) {
    state.caseCode.data = data;
    state.caseCode.catch = moment();
  },
  languageSet(state, data) {
    state.language.data = data;
    state.language.catch = moment();
  },
  setAirport(state, data) {
    state.airportList = data.result;
  },
  setPort(state, data) {
    state.portList = data.result;
  },
  positionSet(state, data) {
    state.position.data = data;
    state.position.catch = moment();
  },
  addalertbox(state, data) {
    state.alertbox.push({
      title: data.title,
      type: data.type,
      name: data.name,
      Time: 3
    });
  },
  removealertbox(state, index) {
    state.alertbox.splice(index, 1);
  },
  addloading(state, data) {
    state.loading.isLoading = true;
  },
  removeloading(state, index) {
    state.loading.isLoading = false;
  },
  hotCase(state, data) {
    state.hotCaseList = data.result;
  },
  hotPerson(state, data) {
    state.hotPersonList = data.result;
  },
  lang(state, lang) {
    if (lang === 'zh_tw') {
      state.publang = zh_tw;
    } else if (lang === 'English') {

    }

    // console.log('langa',state.publang);
  },
  inviteModal(state, data) {
    state.inviteModal = data;
  },
  undertakeModal(state, data) {
    state.undertakeModal = data;
  }
}

const getters = {
  baseMember(state) {
    state.baseMenu = []
  },
  filterCountry(state) {
    return state.country.data.map(item => {
      item = {
        name: item.city_name,
        value: item.city_name
      }
      return item;
    });
  },
  filterAirport() {
    return state.airportList.map(item => {
      item = {
        name: item.name,
        value: item.name
      }
      return item;
    });
  },
  filterPort() {
    return state.portList.map(item => {
      return {
        name: item.name,
        value: item.name
      }
    });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
