const getters = {
    // calendar: state => state.Talent.calendarLists
    filterCountry: state => {
        return state.Public.country.data.map(item=>{
            return item = {
                name: item.city_name,
                value: item.city_name,
            }
        })
    }
};
export default getters;
  