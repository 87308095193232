var th = "";
import http from "./support"

export default {
  EditGroup(data, method) {
    return http.post("Group/Edit/" + method, data)
  },
  InsertGroupMember(data, uid) {
    return http.post("Group/InsertGroupMember/" + uid, data);
  },
  inviteGroup(uid, person = "") {
    return http.post("Group/inviteGroup/" + uid, {
      person: person
    });
  },
  getGroupMemmber(uid) {
    return http.get("Group/getGroupMemmber/" + uid);
  },
  removeMember(uid) {
    return http.post("Group/removeMember/" + uid);
  },
  DeleteGroup(data, uid) {
    return http.post("Group/DeleteGroup/" + uid, data);
  },
  getpersonGroup(data) {
    return http.get("Group/getpersonGroup", data);
  },
  getGroupMemmberCalender(uid, data) {
    return http.post("Group/getGroupMemmberCalender", data);
  },
  setTempCalendar(data) {
    return http.post("Group/setTempCalendar", data);
  },
  CancelTempCase(data) {
    return http.post("Group/CancelTempCase", data);
  },
  GetGroupCompanyList(data) {
    return http.post("Group/GetGroupCompanyList", data);
  },
  RemoveGroupMember(data){
    return http.post("Group/RemoveGroupMember", {person:data});
  }
}