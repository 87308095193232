import axios from "axios";
import CryptoJS from 'crypto-js';
import base64 from "crypto-js/enc-base64";
import store from "@/store";
let cancelList = {};
const encrypt = (text) => {
  if (typeof (text) != "string") {
    text = JSON.stringify(text);
  }
  var key = base64.parse(process.env.VUE_APP_KEY); //为了避免补位，直接用16位的秘钥
  var iv = CryptoJS.enc.Latin1.parse(process.env.VUE_APP_IV); //16位初始向量
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  return btoa(JSON.stringify({
    iv: process.env.VUE_APP_IV,
    value: encrypted.toString(),
    mac: CryptoJS.SHA512(iv).toString()
  }));
};
const http = {
  "get"(path, data, config, cancelname = null) {
    if (cancelname && cancelList[cancelname]) {
      cancelList[cancelname]();
      console.log("我有進來喔");
    }
    let tmp = [];
    data = (data || {});
    $.each(data, (keys, item) => {
      const val = typeof (item) == "string" || typeof (item) == "number" ? item : (item ?
        item.join(
          ",") : "");
      tmp.push(keys + "=" + val);
    });
    const CancelToken = axios.CancelToken;
    let cancel;
    const result = axios({
      method: 'get',
      url: process.env.VUE_APP_PATH + "/" + path + (tmp.length > 0 ? "?" + tmp.join(
        "&") : ""),
      data: data,
      responseType: 'json',
      cancelToken: new CancelToken(c => { //強行中斷請求要用到的
        (cancelname) ? cancelList[cancelname] = c: "";
      })
    });
    result.catch((thrown) => {
      console.log(thrown.response);
      store.dispatch("Public/addAlert", {
        title: 'Status:' + thrown.response.status,
        name: thrown.response.data.result.msg,
        type: "danger"
      })
      // console.log(thrown.response);
    })
    return result;
  },
  "post"(path, data, config, cancelname = null) {
    if (cancelname && cancelList[cancelname]) {
      cancelList[cancelname]();
    }
    const CancelToken = axios.CancelToken;
    const result = axios({
      method: 'post',
      url: process.env.VUE_APP_PATH + "/" + path,
      data: data,
      responseType: 'json',
      cancelToken: new CancelToken(c => { //強行中斷請求要用到的
        (cancelname) ? cancelList[cancelname] = c: "";
      })
    });

    result.catch((thrown) => {
      if (thrown.response) {
        store.dispatch("Public/addAlert", {
          title: 'Status:' + thrown.response.status,
          name: thrown.response.data.result.msg,
          type: "danger"
        })
      }
    })
    return result;
  }
}




export default {
  ...http,
  encrypt: encrypt,
}