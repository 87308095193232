import backstage from "@/service/backstage";

let state = {
  list:{
    data:[],
    count:0,
  },
  detail:{}
};


// actions
const actions = {
  report({
  }, data) {
    const result = backstage.report(data.info, data.files);
    return result;
  },
  reportList({
    commit
  }, data) {
    const result = backstage.reportList();
    result.then(function(data){
      commit("reportListSet",data.data)
    });
    return result;
  },
  getDetailReport({
    commit
  }, data) {
    // console.log(data);
    const result = backstage.getDetailReport(data);
    
    result.then(function(data){
      commit("reprotDetailSet",data.data)
    });
    return result;
  },
  dealErrorItem({
    commit
  }, data) {
    const result = backstage.dealErrorItem(data);
    return result;
  },
};

// mutations
const mutations = {
  reportListSet(state,data){
    state.list.data = data.result;
    state.list.count = data.count;
  },
  reprotDetailSet(state,data){
    state.detail = data.result;
  },
};
const getters = {

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
