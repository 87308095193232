import Vue from 'vue';
import Router from 'vue-router';
import store from "@/store";
// import Home from './views/Home.vue';

Vue.use(Router);

let getpage = (name, path = 'views') => () => {
  return import(`./${path}/${name}.vue`);
}
let selectMethod = (memberpath, companypath) => () => {
  const path = (store.state.user.method === "company") ? companypath : memberpath;
  return import(`./views/${path}.vue`);
}
const redirect = () => {
  const defaultRoute = {
    name: 'index'
  };
  const local = JSON.parse(window.localStorage.getItem('vuex'));
  const isIos = () => window.navigator.userAgent.indexOf('iPhone') !== -1;
  // @ts-ignore
  const isStandalone = () => window.navigator.standalone;
  if (!local) {
    return defaultRoute;
  }

  const {
    route,
    lastLaunch
  } = local;
  // console.log(route, local);
  const now = new Date().valueOf();

  if (route && now - lastLaunch < REDIRECT_TIME_LIMIT) {
    return route.name == "thought" ? defaultRoute : {
      name: route.name
    };
  }

  return defaultRoute;
};
const REDIRECT_TIME_LIMIT = 3600000;
const router = new Router({
  mode: process.env.VUE_APP_MODEL,
  base: process.env.VUE_APP_URL,
  fallback: true,
  routes: [{
      path: '/',
      name: "thought",
      redirect: redirect(),
    }, {
      path: '/index',
      name: 'index',
      components: {
        header: getpage("HeaderInfo", "components"),
        mainbody: getpage("index"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆'
      }
    },
    {
      path: '/SignIn',
      name: 'SignIn',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("SectionSearch", "components"),
        mainbody: getpage("Signin"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-註冊會員'
      }
    },
    {
      path: '/invite/group/:uid',
      name: 'inviteGroupMessage',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("SectionSearch", "components"),
        mainbody: getpage("Group/inviteGroupMessage"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '加入群組',

      }
    },
    {
      path: '/search/Talent',
      name: 'searchTalent',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Talent/ProTalent"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-搜尋人才',
        bannertitle: "來找尋你所需的人才吧"
      },
    },
    {
      path: '/search/Brigade',
      name: 'searchBrigade',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Brigade/ProBrigade"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-搜尋旅團',
        bannertitle: "尋找適合自己的旅團吧！"
      },
    },
    {
      path: '/InfoTalent/:uid/:case_uid/:position/:position_uid',
      name: "InfoTalent",
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Talent/InfoTalent"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '達跡旅行社-人才資訊',
      },
    },
    {
      path: '/PublicInfoTalent/:uid',
      name: "PublicInfoTalent",
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Talent/PublicInfoTalent"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-人才資訊',
      },
    },
    {
      path: '/Info/Brigade/:uid',
      name: 'InfoBrigade',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Brigade/InfoBrigade"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-搜尋旅團',
      },
    },
    {
      path: '/BrigadeShowSavePerson',
      name: 'BrigadeShowSavePerson',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Brigade/BrigadeShowSavePerson"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '達跡旅行社-收藏人才',
      },
    },
    {
      path: '/TalentShowSaveCase',
      name: 'TalentShowSaveCase',
      components: {
        header: getpage("HeaderInfo", "components"),
        // sectionSearch: getpage("ProHeader", "components"),
        mainbody: getpage("Talent/TalentShowSaveCase"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-收藏旅團',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      components: {
        header: getpage("HeaderInfo", "components"),
        mainbody: getpage("Member/Person/calendar"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: '旅遊媒婆-會員專區->行事曆',
        checkmember: true,
      }
    },
    {
      path: '/back',
      // name:"back",
      components: {
        header: getpage("HeaderInfo", "components"),
        mainbody: getpage("Member/basemember"),
        footer: getpage("Sectionfooter", "components"),
      },
      children: [{
          path: '/member/menu',
          name: 'membermenu',
          components: {
            context: getpage("Member/membermenu")
          },
          meta: {
            title: '旅遊媒婆-會員專區',
            checkmember: true
          }
        },
        {
          path: '/member/info',
          name: 'Personinfo',
          components: {
            context: selectMethod("Member/Person/memberinfo", "Company/ChildMember")
            // context:getpage("Member/Person/memberinfo")
          },
          meta: {
            title: '旅遊媒婆- 會員專區->個人檔案',
            checkmember: true
          }
        },
        // {
        //   path: '/report',
        //   name: 'report',
        //   components: {
        //     context: getpage("Member/Person/report")
        //   },
        //   meta: {
        //     title: '會員專區 - 回報問題',
        //     checkmember: true
        //   }
        // },
        {
          path: '/ReportList',
          name: 'ReportList',
          components: {
            context: getpage("Member/Person/ReportList")
          },
          meta: {
            title: '旅遊媒婆-會員專區->問題列表',
            checkmember: true
          }
        },
        {
          path: '/RepostDetail',
          name: 'RepostDetail',
          components: {
            context: getpage("Member/Person/RepostDetail")
          },
          meta: {
            title: '會員專區 - 問題明細',
            checkmember: true
          }
        },
        {
          path: '/member/deposit',
          name: 'deposit',
          components: {
            context: getpage("Member/Person/deposit")
          },
          meta: {
            title: '會員專區 - 加值系統',
            checkmember: true,
          }
        }, {
          path: '/member/TransactionRecord',
          name: 'TransactionRecord',
          components: {
            context: getpage("Member/Person/TransactionRecord")
          },
          meta: {
            title: '會員專區 - 購買紀錄',
            checkmember: true,
          }
        }, {
          path: '/member/IncomeStatement',
          name: 'IncomeStatement',
          components: {
            context: getpage("Member/IncomeStatement")
          },
          meta: {
            title: '會員專區 - 收支列表',
            checkmember: true,
          }
        }, {
          path: '/member/IncomeStatement/info/:uid',
          name: 'IncomeStatementInfo',
          components: {
            context: getpage("Member/IncomeStatementInfo")
          },
          meta: {
            title: '會員專區 - 收支列表明細',
            checkmember: true,
          }
        }, {
          path: '/changepassword',
          name: 'changepassword',
          components: {
            context: getpage("Member/Person/changepassword")
          },
          meta: {
            title: '會員專區 - 修改密碼',
            checkmember: true,
          }
        }, {
          path: '/alertbox',
          name: 'Memberalertbox',
          components: {
            context: getpage("Member/Person/alertbox")
          },
          meta: {
            title: '會員專區 - 通知',
            checkmember: true

          }
        }, {
          path: '/message',
          name: 'Membermessage',
          components: {
            context: getpage("Member/Person/messagebox")
          },
          meta: {
            title: '會員專區 - 訊息',
            checkmember: true

          }
        },
        // {
        //   path: '/Resume',
        //   name: 'MemberResume',
        //   components: {
        //     context: getpage("Member/Person/resume")
        //   },
        //   meta: {
        //     title: '會員專區 - 個人履歷',
        //     checkmember: true

        //   }
        // }
        , {
          path: '/addResume',
          name: 'addResume',
          components: {
            context: getpage("Member/Person/addResume")
          },
          meta: {
            title: '會員專區 - 新增履歷',
            checkmember: true

          }
        }, {
          path: '/editResume',
          name: 'editResume',
          components: {
            context: getpage("Member/Person/editResume")
          },
          meta: {
            title: '會員專區 - 履歷編輯',
            checkmember: true

          }
        }, {
          path: '/blacklist',
          name: 'Memberblacklist',
          components: {
            context: getpage("Member/Person/blacklist")
          },
          meta: {
            title: '會員專區 - 黑名單(公司)',
            checkmember: true

          }
        },
        {
          path: '/Application/Identity',
          name: 'ApplicationIdentity',
          components: {
            context: getpage("Member/ApplicationIdentity")
          },
          meta: {
            title: '申請功能',
            checkmember: true
          }
        },
        {
          path: '/checkemail',
          name: 'checkemail',
          components: {
            context: getpage("Member/Person/checkemail")
          },
          meta: {
            title: '會員專區 - 信箱認證',
            checkmember: true
          }
        }, { //----------------接暗管裡----------------
          path: '/takecasemanage/AllTakes',
          name: 'AllTakes',
          components: {
            context: getpage("Member/Person/TakeCaseManage/AllTakes")
          },
          meta: {
            title: '接案管裡 - 接案總覽',
            checkmember: true
          }
        }, {
          path: '/matching',
          name: 'matching',
          components: {
            context: getpage("Member/Person/TakeCaseManage/matching")
          },
          meta: {
            title: '接案管裡 - 媒合中',
            checkmember: true
          }
        }, {
          path: '/MatchCompleted',
          name: 'MatchCompleted',
          components: {
            context: getpage("Member/Person/TakeCaseManage/MatchCompleted")
          },
          meta: {
            title: '接案管裡 - 媒合完成',
            checkmember: true
          }
        }, {
          path: '/TakerDiscuss',
          name: 'TakerDiscuss',
          components: {
            context: getpage("Member/Person/TakeCaseManage/TakerDiscuss")
          },
          meta: {
            title: '接案管裡 - 違約異議',
            checkmember: true
          }
        }, {
          path: '/takeCase_manage/FinishedTakeCase',
          name: 'FinishedTakeCase',
          components: {
            context: getpage("Member/Person/TakeCaseManage/FinishedTakeCase")
          },
          meta: {
            title: '接案管裡 - 結案',
            checkmember: true
          }
        }, {
          path: '/HistoryTakeCase',
          name: 'HistoryTakeCase',
          components: {
            context: getpage("Member/Person/TakeCaseManage/HistoryTakeCase")
          },
          meta: {
            title: '接案管裡 - 歷史接案',
            checkmember: true
          }
        },
        //----------------企業管理----------------
        {
          path: '/CompanyProfile',
          name: 'CompanyProfile',
          components: {
            context: getpage("Company/ChildMember")
          },
          meta: {
            title: '企業管理 - 企業簡介',
            checkmember: true
          }
        }, {
          path: '/CompanyCase',
          name: 'CompanyCase',
          components: {
            context: getpage("Company/CompanyCase")
          },
          meta: {
            title: '企業管理 - 所有Case',
            checkmember: true
          }
        },
        {
          path: '/Companygroup',
          name: 'Companygroup',
          components: {
            context: getpage("Company/Companygroup")
          },
          meta: {
            title: '企業管理 - 群組管理',
            checkmember: true
          }
          // 
        }, {
          path: '/groupCalendarManage/:sourcepage',
          name: 'groupCalendarManage',
          components: {
            context: getpage("Group/GroupCalendar")
          },
          meta: {
            title: '群組分配行事曆',
            checkmember: true
          }
          // groupCalendarManage
        }, {
          path: '/GroupCompanyList',
          name: 'GroupCompanyList',
          components: {
            context: getpage("Group/GroupCompanyList")
          },
          meta: {
            title: '公司群組名單',
            checkmember: true
          }
          // groupCalendarManage
        },
        //群組
        {
          path: '/groupMember/:sourcepage/:uid',
          name: 'groupMember',
          components: {
            context: getpage("Group/GroupMember")
          },
          meta: {
            title: '群組',
            checkmember: true
          }
        }, {
          path: '/GroupList',
          name: 'mygroup',
          components: {
            context: getpage("Group/GroupList")
          },
          meta: {
            title: '群組',
            checkmember: true
          }
        },
        //發案管理
        {
          path: '/casemanagement/:sourcepage/:method', //add , edit
          name: 'EditCase',
          components: {
            context: getpage("Member/case/EditCase")
          },
          meta: {
            title: '發案管理',
            checkmember: true
          },
          beforeEnter: (to, from, next) => {
            // console.log("d");
            const pre = from.method == "add" ? "新增" : "修改";
            document.title = document.title + " - " + pre + "案子";
            next();
          }
        },
        {
          path: '/ShowPosition/:uid/:sort',
          name: 'ShowPosition',
          components: {
            context: getpage("Member/case/CaseManage/ShowPosition")
          },
          meta: {
            title: '發案管理 - 職位顯示',
            checkmember: true
          },
        }, {
          path: '/RecommendTalent/:uid/:position/:position_uid',
          name: 'RecommendTalent',
          components: {
            context: getpage("Member/case/RecommendTalent")
          },
          meta: {
            title: '發案管理 - 推薦邀請',
            checkmember: true
          },
        },
        {
          path: '/GetHirePerson/:uid/:position_uid',
          name: 'GetHirePerson',
          components: {
            context: getpage("Member/case/GetHirePerson")
          },
          meta: {
            title: '發案管理 - 錄取人才',
            checkmember: true
          },
        }, {
          path: '/ReviewPerson/:uid/:position_uid',
          name: 'ReviewPerson',
          components: {
            context: getpage("Member/case/ReviewPerson")
          },
          meta: {
            title: '發案管理 - 審核人才',
            checkmember: true
          },
        }, { //----------------發案管理----------------
          path: '/casemanagement/AllCases',
          name: 'AllCases',
          components: {
            context: getpage("Member/case/CaseManage/AllCases")
          },
          meta: {
            title: '發案管理 - 旅團總覽',
            checkmember: true,
          }
        }, {
          path: '/CacheCase',
          name: 'CacheCase',
          components: {
            context: getpage("Member/case/CaseManage/CacheCase")
          },
          meta: {
            title: '發案管理 - 暫存旅團',
            checkmember: true
          }
        }, {
          path: '/Recruiting',
          name: 'Recruiting',
          components: {
            context: getpage("Member/case/CaseManage/Recruiting")
          },
          meta: {
            title: '發案管理 - 徵才中',
            checkmember: true
          }
        }, {
          path: '/RecruitCompleted',
          name: 'RecruitCompleted',
          components: {
            context: getpage("Member/case/CaseManage/RecruitCompleted")
          },
          meta: {
            title: '發案管理 - 徵才完畢',
            checkmember: true
          }
        }, {
          path: '/casemanagement/AllCases/Info/:uid',
          name: 'OwnerCaseInfo',
          components: {
            context: getpage("Member/case/OwnerCaseInfo"),
          },
          meta: {
            title: '達跡旅行社-旅團資訊',
          },
        }, {
          path: '/EditPosition/:case_uid/:position_name/:position_uid',
          name: 'EditPosition',
          components: {
            context: getpage("Member/case/CaseManage/EditPosition")
          },
          meta: {
            title: '發案管理 - 職位編輯',
            checkmember: true
          }
        }, {
          path: '/AddPositionx/:case_uid',
          name: 'AddPosition',
          components: {
            context: getpage("Member/case/CaseManage/AddPosition")
          },
          meta: {
            title: '發案管理 - 新增職位',
            checkmember: true
          }
        }, {
          path: '/casemanage/CarryOut',
          name: 'CarryOut',
          components: {
            context: getpage("Member/case/CaseManage/CarryOut")
          },
          meta: {
            title: '發案管理 - 執行中',
            checkmember: true
          }
        }, {
          path: '/casemanage/Finished',
          name: 'Finished',
          components: {
            context: getpage("Member/case/CaseManage/Finished")
          },
          meta: {
            title: '發案管理 - 執行中',
            checkmember: true
          }
        }, {
          path: '/CaseDiscuss',
          name: 'CaseDiscuss',
          components: {
            context: getpage("Member/case/CaseManage/CaseDiscuss")
          },
          meta: {
            title: '發案管理 - 違約異議',
            checkmember: true
          }
        }, {
          path: '/ClosingCase',
          name: 'ClosingCase',
          components: {
            context: getpage("Member/case/CaseManage/ClosingCase")
          },
          meta: {
            title: '發案管理 - 結案',
            checkmember: true
          }
        }, {
          path: '/HistoryTaker/:uid/:position_uid',
          name: 'HistoryTaker',
          components: {
            context: getpage("Member/case/CaseManage/HistoryTaker")
          },
          meta: {
            title: '發案管理 - 歷史應徵者',
            checkmember: true
          }
        }
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      // component: getpage("test"),
      meta: {
        title: '旅遊媒婆'
      },

    },
    {
      path: '/test',
      name: 'test',
      components: getpage("test"),
      meta: {
        title: '達跡旅行社'
      }
    },
    {
      path: '/404',
      name: '404',
      components: {
        header: getpage("HeaderInfo", "components"),
        mainbody: getpage("NotFoundPage"),
        footer: getpage("Sectionfooter", "components"),
      },
      meta: {
        title: "找不到頁面"
      }
    },
    // {
    //   path: '*',
    //   redirect: '/404'
    // }
  ],
});
// console.log('router' ,router.options);
router.beforeEach(async (to, from, next) => {
  document.title = (to.meta) ? to.meta.title : document.title;
  if (to.name == 'logout') {
    store.dispatch("user/logout").then(function (data) {
      store.dispatch("Public/addAlert", {
        type: "success",
        "name": "登出成功"
      });
      next({
        name: "index"
      });
    });
  } else {
    const checkmember = to.meta.checkmember;
    // console.log(checkmember);
    if (to.name == 'index') {
      next();
      // return;
    }

    if (store.state.user.login) {
      next();
    } else {

    }
    store.dispatch("user/checkUser").then(function (data) {
      if (checkmember) {
        if (store.state.user.login) {
          next();
        } else {
          next({
            name: "index"
          });
        }
      } else {
        next();
      }
    });

  }
});
router.afterEach(async (to, from, next) => {
  localStorage["from"] = (localStorage["from"] != from.name && from.name) ? from.name : (
    localStorage["from"] || "");
  localStorage["to"] = (localStorage["to"] != to.name && to.name) ? to.name : localStorage[
    "to"];
  // console.log(to,from,localStorage);
  $('body,html').scrollTop(0, 500);
})
export default router;