import aj_group from '@/service/Group';
import store from '..';

let state = {
  GroupborderList: {
    "warning": "接受/邀請",
    "check": "待確認",
    "success": "完成",
    "info": "承接",
    "primary": "結案",
    "Temp": "暫存"
  },
  GroupInfo: {},
  GroupMember: {
    groupuid: "",
    latetime: "",
    list: []
  },
  GroupList: {
    list: []
  },
  GroupCalenderData: {
    list: []
  },
  GroupCalendarManage: {
    member: [],
    caseinfo: {},
    mycase: {
      onlineCase: [],
      TempCase: []
    }
  },
  GroupMemberlist: []
}

const actions = {
  InsertGroup({}, data) {
    const result = aj_group.EditGroup(data, "insert");
    return result;
  },
  UpdateGroup({}, data) {
    const result = aj_group.EditGroup(data, "update");
    return result;
  },
  InsertGroupMember({
    dispatch,
    commit
  }, data) {
    data.lastUpTime = state.GroupMember.latetime;
    const result = aj_group.InsertGroupMember(data.info, data.groupuid);

    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          name: '發送成功',
          type: "success"
        }, {
          root: true
        })
      } else {
        dispatch("Public/addAlert", {
          name: '發送失敗',
          type: "danger"
        }, {
          root: true
        })
      }
    })
    return result;
  },
  inviteGroup({}, data) {
    const result = aj_group.inviteGroup(data.groupUid, data.person);
    result.then((data) => {
      console.log(data);
    });
    return result;
  },
  getGroupMemmberCalender({
    commit,
    dispatch
  }, data) {
    const result = aj_group.getGroupMemmberCalender(data.uid, data);
    result.then((data) => {
      if (data.data.state) {
        commit("setgroupCalendarManage", data.data.result);
      }
    });
    return result;
  },
  getGroupMemmber({
    commit
  }, data) {
    const result = aj_group.getGroupMemmber(data.uid);
    result.then((data) => {
      if (data.data.state) {
        commit("setGroupData", data.data.result);
      }
    });
    return result;
  },
  removeMember({
    commit
  }, data) {
    const result = aj_group.removeMember(data);
    result.then((data) => {
      if (data.data.state) {
        store.dispatch("Public/addAlert", {
          name: "刪除成功",
          type: "success"
        });
        commit("setGroupData", data.data.result);
      } else {
        store.dispatch("Public/addAlert", {
          name: data.data.state.msg,
          type: "danger"
        });
      }
      console.log(data);
    })
    return result;
  },
  DeleteGroup({
    dispatch
  }, data) {
    const result = aj_group.DeleteGroup(data, data.uid);
    const tdata = data;
    result.then((data) => {
      if (data.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "刪除-" + tdata.name
        }, {
          root: true
        });
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: data.data.result.msg
        }, {
          root: true
        });
      }
    })
    return result;
  },
  personGroup({
    dispatch,
    commit
  }, data) {
    const result = aj_group.getpersonGroup({
      start: data.start,
      end: data.end
    });
    result.then((data) => {
      if (data.data.state) {
        commit("setPersonGroup", data.data.result);
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: data.data.result.msg
        }, {
          root: true
        });
      }
    });
    return result;
  },
  setTempCalendar({
    commit,
    dispatch
  }, data) {
    const result = aj_group.setTempCalendar(data);
    result.then((data) => {
      if (data.data.state) {
        commit("setgroupCalendarManage", data.data.result);
      }
    });
    return result;
  },
  CancelTempCase({
    commit,
    dispatch
  }, data) {
    const result = aj_group.CancelTempCase(data);
    result.then((data) => {
      if (data.data.state) {
        commit("setgroupCalendarManage", data.data.result);
      }
    });
    return result;
  },
  GetGroupCompanyList({
    commit,
    dispatch
  }, data) {
    const result = aj_group.GetGroupCompanyList(data);
    result.then((data) => {
      commit("setGroupCompanyList", data.data.result);
    });
    return result;
  },
  RemoveGroupMember({
    commit,
    dispatch
  },data) {
    const result = aj_group.RemoveGroupMember(data.person);
  }
}

const mutations = {
  setGroupData(state, data) {
    state.GroupInfo = data.groupinfo;
    state.GroupMember.list = data.memberlist;
    state.GroupMember.groupuid = data.groupinfo.uid;

  },
  setPersonGroup(state, data) {
    state.GroupList.list = data;
  },
  setGroupCalenderData(state, data) {
    state.GroupInfo = data.groupinfo;
    state.GroupCalenderData.list = data.memberlist;
  },
  setgroupCalendarManage(state, data) {
    state.GroupCalendarManage.member = data.memberlist;
    state.GroupCalendarManage.mycase.onlineCase = data.myCase.onlineCase;
    state.GroupCalendarManage.mycase.TempCase = data.myCase.TempCase;
  },
  setGroupCompanyList(state, data) {
    state.GroupMemberlist = data;

  }
}

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}