import http from "./support";

export default {

  get: (url) => {
    return http.get(url);
  },
  // login: (email, pw, provider = 'person', method) => {
  //   const loginstate = http.post("login", {
  //     email: email,
  //     password: pw,
  //     provider: provider,
  //     method: method,
  //   });
  //   return loginstate;
  // },
  login: (provider = 'person', method, social, data) => {
    let loginstate;
    if (social === 'local') {
      loginstate = http.post("login", {
        email: data.email,
        password: data.password,
        provider: provider,
        method: method,
        social: social
      });
    } else {
      loginstate = http.post("login", {
        access_token: data,
        provider: method,
        method: provider,
        social: social
      });
    }

    return loginstate;
  },
  checkUser: () => {
    return http.get("checkuser");
  },

  //====================忘記密碼====================

  forgotToSendMail: (data) => {
    return http.post("sendEmailToReset", {
      data: data
    });
  },

  forgotToVerification: (data) => {
    return http.post("resetPwd/verification", {
      code: data
    });
  },

  resetPwd: (data) => {
    return http.post("resetPwd", {
      data: data
    });
  },
  //====================忘記密碼====================


  // showresume: () => {
  //   return http.get("showresume", {});
  // },
  pushcase: () => {
    return http.get("getWaitCaseName");
  },
  changepwd: (pwd) => {
    return http.post("Member/changepwd", {
      data: pwd
    });
  },
  register: (data) => {
    return http.post("register", {
      data: data
    });
  },
  verification: (checkcode) => {
    return http.post("verification", {
      code: checkcode
    });
  },
  getuserinfo: () => {
    return http.get("showmember");
  },
  getCompanyInfo: () => {
    return http.get("showCompany");
  },
  updateuserinfo: (data, file) => {
    let form = new FormData;
    if (file) {
      form.append("file", file.file);
    }
    $.map(data, (item, keys) => {
      if (typeof (item) == "object") {
        form.append(keys, JSON.stringify(item));
      } else {
        form.append(keys, item);
      }
    });
    return http.post("updateprofile", form, {
      headers: {
        "Content-Type": undefined
      }
    });
  },
  updateCompanyInfo: (data, file) => {

    let form = new FormData;
    if (file) {
      form.append("file", file.file);
    }
    $.map(data, (item, keys) => {
      if (typeof (item) == "object") {
        form.append(keys, JSON.stringify(item));
      } else {
        form.append(keys, item);
      }
    });
    return http.post("Company/updateCompanyProfile", form, {
      headers: {
        "Content-Type": undefined
      }
    });
  },
  addCard: (data) => {
    // console.log(data);
    const response = http.encrypt(data);
    return http.post("addCard", {
      data: response
    });
  },
  addValue: (data) => {
    // console.log(data);
    const response = http.encrypt(data);
    return http.post("addValue", {
      data: response
    })
  },
  PointTransactionRecord: () => {
    return http.get("PointTransactionRecord");
  },
  showDetailNotice: (page, searchdata = {}, orderBy = {
    name: "createtime",
    order: "desc"
  }) => {
    return http.post("showDetailNotice", {
      page: page,
      searchdata: searchdata,
      orderBy: orderBy
    });
  },
  countNotice: () => {
    return http.get("countNotice");
  },
  AllReadNotice: () => {
    return http.post("AllReadNotice");
  },
  readNotice: (data) => {
    return http.post("readNotice", data);
  },
  authority: (data, sort, file) => {
    let form = new FormData;
    if (file) {
      form.append("file", file.file);
    }
    $.map(data, (item, keys) => {
      if (typeof (item) == "object") {
        form.append(keys, JSON.stringify(item));
      } else {
        form.append(keys, item);
      }
    });
    form.append("sort", sort);
    return http.post("authority", form, {
      headers: {
        "Content-Type": undefined
      }
    });
  },
  getMemberMenuCount: () => {
    return http.get("getMemberMenuCount");
  },
  againsendcheckmail(data) {
    return http.get("Member/AgainSendcheckmail/" + data)
  },
  socialBinding(data) {
    return http.post("socialBinding", data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  },
}
