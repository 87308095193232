var th = "";
import http from "./support"

export default {
  Resign(data) {
    return http.post("Company/Resign", {
      data: http.encrypt(data)
    },{},"Resign");
  },
  getAccount() {
    return http.get("Company/getAccount");
  },
  getchildmember() {
    return http.get("Company/getchildmember");
  },
  changeAccountData(data) {
    return http.post("Company/changeAccountData", data);
  },
  insertUser(data) {

    return http.post("Company/insertUser", data);
  },
  deleteUid(data) {
    return http.post("Company/deleteUid/" + data);
  },
  changepassword(data) {
    return http.post("Company/changepassword", data);
  },
  CompanyAllCase(orderby, page, search) {
    return http.post(
      `Company/CompanyAllCase/${page.page}/${page.pagenumber}/${orderby.name}/${orderby.order}`,
      search);
  },
  getCompanyGroup(orderby, page, search,method) {
    return http.post(
      `Company/getGroupList/${method}/${page.page}/${page.pagenumber}/${orderby.name}/${orderby.order}`,
      search);
  }

}