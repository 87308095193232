import Talent from '@/service/Talent';
import router from '@/router';
// import Public from '@/service/';

const state = {
  Prolist: {
    count: 0,
    data: []
  },
  onepro: {
    proinfo: {}
  },
  getCaseList: [],
  List: {
    count: 0,
    data: [],
  },
  GetAllReviewerList: [],
  showHistoryRecordCompleteList: [],
  TakerDiscussList: [],
  detailPersonList: {
    img: "",
    username: "",
    address: "",
    profile: "",
    expectSalary: 0,
    finishTimes: 0,
    defaultTimes: 0,
    skill: [],
    language: [],
    licence: [],
    historyTake: [],
    eveluation: {
      canReadMoer: false,
      comment: [],
      star: [0, 0, 0, 0, 0],
      totalComment: 0
    },
    limit: false,
  },
  calendarLists: {},
  CalendarAlgorithmCaseList: [],
  tmplist: {},
  showresumeList: [],
  showCardList: [],
  takecasesort: [{
    name: '總覽',
    linkname: 'AllTakes',
    sort: 'all',
  },
  {
    name: '媒合中',
    linkname: 'AllTakes',
    sort: ['accept', 'money', 'check'],
  },
  {
    name: '媒合完成',
    linkname: 'AllTakes',
    sort: ['confirm', 'carryOut'],
  },
  {
    name: '執行完成',
    linkname: 'AllTakes',
    sort: ['finished'],
  },
]
}
// getters
// actions
const actions = {

  getPerson({
    commit
  }, data) {
    const result = Talent.searchlist(data.search, data.page);
    result.then((data) => {
      commit("setPersonList", data.data);
    });
    return result;
  },
  unLoginShowPerson({
    commit
  }, data) {
    const result = Talent.unLoginShowPerson(data.search, data.page);
    result.then((data) => {
      commit("setPersonList", data.data);
    });
    return result;
  },
  showSavePerson({
    commit
  }, data) {
    const result = Talent.showSavePerson(data.search, data.page);
    result.then((data) => {
      commit("setPersonList", data.data);
    });
    return result;
  },
  getfilter({
    commit
  }, data) {
    const searchtype = data.searchtype;
    const result = Talent.filterPerson(data.search, data.page, searchtype);
    result.then((data) => {
      commit("setPersonList", data.data);
    })
    return result;
  },
  recommendCase({
    commit
  }, data) {
    const result = Talent.recommendCase(data.search, data.page);
    result.then((data) => {
      commit("setPersonList", data.data);
    })
    return result;
  },
  showSaveCase({
    commit
  }) {
    const result = Talent.showSaveCase();
    result.then((data) => {
      commit("setProList", data.data);
    });
    return result;
  },
  savecase({
    commit,
    dispatch
  }, uid) {
    const result = Talent.savecase(uid);
    result.then(function (data) {
      if (data.data.state) {
        if (!!data.data.result.isopen) {
          dispatch("Public/addAlert", {
            type: "success",
            name: "收藏完畢"
          }, {
            root: true
          });
        } else {
          dispatch("Public/addAlert", {
            type: "danger",
            name: "取消收藏"
          }, {
            root: true
          });
        }
        commit("savecase", data.data);
      }
    });
    return result;
  },
  inviteUser({
    dispatch
  }, data) {
    const result = Talent.inviteUser(data.data);
    result.then((res) => {
      if (res.data.state) {
        if (res.data.result.code == 4) {
          dispatch("Public/addAlert", {
            type: "danger",
            name: "名額已滿"
          }, {
            root: true
          });
        } else if (res.data.result.code == 5) {
          dispatch("Public/addAlert", {
            type: "warning",
            name: "邀請-"
          }, {
            root: true
          });
        } else {
          dispatch("Public/addAlert", {
            type: "success",
            "name": "邀請成功"
          }, {
            root: true
          });
        }
        if (!data.norouter) {
          router.push({
            name: 'ReviewPerson',
            params: {
              uid: data.case_uid,
              position_uid: data.position_uid
            }
          });
        }
      }
    });
    return result;
  },
  getCase({
    commit
  }, data) {
    let result;
    result = Talent.getCase(data.page);
    result.then(function (data) {
      commit("getCaseSet", data.data)
    })
    return result;
  },
  getAllTakeCases({
    commit
  }, data) {
    let result;
    result = Talent.AllTakeCases(data.page, data.search, data.orderBy);
    result.then(function (data) {
      commit("AllTakeCasesSet", data.data.result);
    })
    return result;
  },
  GetAllReviewer({
    commit
  }, data) {
    let result;
    result = Talent.GetAllReviewer(data.page, data.search);
    result.then(function (data) {
      commit("GetAllReviewerSet", data.data)
    })
    return result;
  },
  GetPayPromisemoney({
    dispatch
  }, data) {
    const result = Talent.PayPromisemoney(data);
    result.then(function (res) {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "付款成功"
        }, {
          root: true
        });
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: "付款失敗"
        }, {
          root: true
        });
      }
    });
    return result;
  },
  takerDefault({
    dispatch
  }, data) {
    const result = Talent.takerDefault(data);
    result.then((res) => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "已成功違約"
        }, {
          root: true
        });
        router.push({
          name: 'HistoryTakeCase'
        });
      }
    });
    return result;
  },
  takerObjection({
    dispatch
  }, data) {
    const result = Talent.takerObjection(data);
    result.then((res) => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "提出違約成功"
        }, {
          root: true
        });
        router.push({
          name: 'TakerDiscuss'
        });
      } else {
        dispatch("Public/addAlert", {
          type: "danger",
          name: "提出失敗"
        }, {
          root: true
        });
      }
    });

    return result;
  },
  acceptInvite({
    dispatch
  }, data) {
    const result = Talent.acceptInvite(data);
    result.then((res) => {
      if (res.data.state) {
        dispatch("Public/addAlert", {
          type: "success",
          name: "回復成功"
        }, {
          root: true
        });
      }
    });
    return result;
  },
  showHistoryRecordComplete({
    commit
  }, data) {
    const result = Talent.showHistoryRecordComplete(data.page);
    result.then((data) => {
      commit('showHistoryRecordCompleteSet', data.data)
    });
    return result;
  },
  TakerDiscuss({
    commit
  }, data) {
    const result = Talent.TakerDiscuss(data.page);
    result.then((data) => {
      commit('TakerDiscussSet', data.data.result)
    });
    return result;
  },
  detailPerson({
    commit
  }, data) {
    const result = Talent.detailPerson(data);
    result.then((data) => {
      commit('detailPersonSet', data.data)
    });
    return result;
  },
  findPersonByEmail({
    dispatch,
    commit
  }, data) {
    if (data.email === "") {
      dispatch("Public/addAlert", {
        type: "danger",
        name: '請輸入email'
      }, {
        root: true
      });
    } else {
      const result = Talent.findPersonByEmail(data);
      result.then(res => {
        if (res.data.state) {
          commit("detailPersonSet", res.data.result);
        } else if (res.data.result.msg === "can not found any member") {
          dispatch("Public/addAlert", {
            type: "danger",
            name: '查無此人'
          }, {
            root: true
          });
        }
      });
      return result;
    }
  },
  showcalendar({
    commit
  }, data) {
    const result = Talent.showcalendar(data);
    result.then((res) => {
      let calendarData = {
        header: {
          items: [{
            name: "完成",
            events: res.data.result.takeCase,
            colorClass: []
          }]
        },
        content: {
          items: [{
            name: "待處理",
            events: res.data.result.waitting,
            colorClass: []
          }]
        },
        footer: {
          items: [{
            name: "推薦",
            events: res.data.result.recommendCaseArray,
            colorClass: []
          }]
        }
      };
      // console.log(calendarData);
      commit("calendarSet", calendarData);
    })
    return result;
  },
  CalendarAlgorithmCase({
    commit
  }) {
    const result = Talent.CalendarAlgorithmCase();
    result.then((data) => {
      commit("CalendarAlgorithmCaseSet", data.data);
    })
    return result;
  },
  SendCalendarSaveCaseResume({
    commit
  }, data) {
    const result = Talent.SendCalendarSaveCaseResume(data.data);
    // result.then((data)=>{
    //   commit("CalendarAlgorithmCaseSet" , data.data);
    // })
  },
  AddCalendarItem({
    commit
  }, data) {
    console.log(data);
    const result = Talent.AddCalendarItem(data.data);
  },
  showresume({
    commit
  }, data) {
    const result = Talent.showresume();
    result.then((data) => {
      commit("showresumeSet", data.data);
      // console.log(data);
    });
    return result;
  },
  showCard({
    commit
  }, data) {
    const result = Talent.showCard();
    result.then((data) => {
      commit("showCardSet", data.data);
    });
    return result;
  },
  cancelTake({
    commit,
    rootState,
    dispatch,
  }, data) {
    rootState.Public.loading.isLoading = true;
    const result = Talent.cancelTake(data).then((data) => {
      rootState.Public.loading.isLoading = false;
      if (data.data.state) {
        dispatch('Public/addAlert', {
          type: 'success',
          name: '取消成功'
        }, {
          root: true
        });
      } else {
        dispatch('Public/addAlert', {
          type: 'danger',
          name: '取消失敗'
        }, {
          root: true
        });
      }
    });
    return result;

  },
}
// female 女
// mutations
const mutations = {
  // PayPromisemoneySet(state,data){
  //   state.PayPromisemoneyList = data.result;
  // },
  showCardSet(state, data) {
    state.showCardList = data.result;
  },
  showresumeSet(state, data) {
    state.showresumeList = data.result;
  },
  CalendarAlgorithmCaseSet(state, data) {
    state.CalendarAlgorithmCaseList = data.result;
  },
  calendarSet(state, data) {
    state.calendarLists = data;
  },
  detailPersonSet(state, data) {
    // console.log(data);
    state.detailPersonList = data.result;
  },
  TakerDiscussSet(state, data) {
    state.List.data = data.data;
    state.List.count = data.count;
  },
  showHistoryRecordCompleteSet(state, data) {
    state.List.data = data.result;
    state.List.count = data.count;
  },
  setPersonList(state, data) {
    state.Prolist.data = data.result;
    state.Prolist.count = data.count;
  },
  savecase(state, data) {
    state.onepro.proinfo.isSave = !!data.result.isopen;
    state.detailPersonList.isSave = !!data.result.isopen;
  },
  Prolistsavecase(state, data) {
    const index = data.index;
    const isSave = data.data;
    state.Prolist.data[index].isSave = !!isSave;
  },
  getCaseSet(state, data) {
    state.getCaseList = data.result;
  },
  AllTakeCasesSet(state, data) {
    state.List.data = data.data;
    state.List.count = data.count;
  },
  GetAllReviewerSet(state, data) {
    state.GetAllReviewerList = data.result;
  },
}

const getters = {

};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
