var th = "";
import http from "./support"

export default {
  get: (url) => {
    return http.get(url);
  },
  report: (data, file) => {
    let form = new FormData;
    if (file) {
      form.append("file", file.file);
    }
    $.map(data, (item, keys) => {
      if (typeof (item) == "object") {
        form.append(keys, JSON.stringify(item));
      } else {
        form.append(keys, item);
      }
    });
    return http.post("report", form, {
      headers: {
        "Content-Type": undefined
      }
    });
  },
  reportList:()=>{
    return http.get('getErrorReport');
  },
  getDetailReport:(data)=>{
    // console.log(data);
    return http.get('getDetailReport',{
      uid:data.uid,
    });
  },
  dealErrorItem:(data)=>{
    return http.post('dealErrorItem',{
      data:data
    });
  },

}
