import store from "@/store"
import _ from "lodash"
export default {
  capitalize: (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  filter: (value, where) => {
    const typea = typeof (where);
    const result = value.filter(function (item, keys) {

      if (typea == "string") {
        return item.indexOf(where) !== -1;
      } else {

        const op = $.map(where, (iit, key) => {

          return item[key] == iit;
        }).filter((ite) => {
          return ite;
        }).length;

        return op > 0;

      }
    });
    return result;
  },
  changelang: (value, field) => {
    let result = (store.state.Public.publang[field]) ? store.state.Public.publang[field][value] :
      "";
    return result || value;
    // return ((store.state.publang[field])?store.state.publang[field][value]:"") || value;
  },
  orderBy(value, orderByArray) {

    const field = [];
    const values = [];
    Object.keys(orderByArray).forEach((item) => {
      field.push(item);
      values.push(orderByArray[item]);
    })
    return _.orderBy(value, field, values);
  }
}