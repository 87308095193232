var th = "";
import http from "./support";
export default {

  get: (url) => {
    return http.get(url);
  },
  showcase: (search = {}, page = 1, pagenumber = 20) => {
    return http.post('showcase', {
      search: search,
      page: page,
      pagenumber: pagenumber
    }, {}, "showcase");
  },
  unLoginShowCase: (search = {}, page = 1, pagenumber = 20) => {
    return http.post('unLoginShowCase', {
      search: search,
      page: page,
      pagenumber: pagenumber
    }, {}, "unLoginShowCase");
  },
  GetPushCaseMan: (search = {}, page = 1, pagenumber = 20) => {
    return http.post('GetPushCaseMan', {
      search: search,
      page: page,
      pagenumber: pagenumber
    }, {}, "GetPushCaseMan");
  },
  filterCase: (search, page, searchtype) => {
    const url = searchtype;
    return http.post(url, {
      search: search,
      page: page,
    }, {}, "filterCase")
  },
  undertake: (data) => {
    //search =>  {classs }
    return http.post("undertake", {
      data: data
    }, {}, "undertake");
  },
  prodetail: (data) => {
    return http.post("detail", data, {}, "prodetail");
  },
  savecase: (data) => {
    return http.post("savecase", {
      data: data
    }, {}, "savecase");
  },
  recruiting(page, search = {}, orderBy = {
    name: "createtime",
    order: "desc"
  }) {
    return http.post("pushcase", {
      page: page,
      search: search,
      orderBy: orderBy
    }, {}, "recruiting");
  },
  AllCases(page, search = {}, orderBy = {
    name: "createtime",
    order: "desc"
  }) {
    return http.post("AllCases", {
      page: page,
      search: search,
      orderBy: orderBy
    }, {}, "AllCases");
  },
  showCacheCase: (page, search = {}, orderBy = {
    name: "createtime",
    order: "desc"
  }) => {
    return http.post('showCacheCase', {
      page: page,
      search: search,
      orderBy: orderBy
    });
  },
  Resume: (data) => {
    return http.post("Resume", {
      data: data,
    }, {}, "Resume");
  },
  addcase: (data) => {
    return http.post("addcase", {
      data: data,
    }, {}, "addcase");
  },
  removeCase: (uid) => {
    return http.post("removeCase", {
      case_uid: uid,
    }, {}, "removeCase");
  },
  caseDefault: (data) => {
    return http.post('caseDefault', {
      data: data,
    }, {}, "caseDefault");
  },
  caseObjection: (data) => {
    return http.post('caseObjection', {
      data: data,
    }, {}, "caseObjection");
  },
  GetObjectionList: (page, search = {}, orderBy = {
    name: "createtime",
    order: "desc"
  }) => {
    return http.get('GetObjectionList', {
      page: page,
    }, {}, "GetObjectionList");
  },
  checkInvite: (data) => {
    return http.post('checkInvite', {
      data: data,
    }, {}, "checkInvite");
  },
  cancelInvite: (data) => {
    return http.post('cancelInvite', {
      data: data,
    }, {}, "cancelInvite");
  },
  completeCase: (data) => {
    return http.post('complete', {
      case_uid: data
    }, {}, "complete");
  },
  cacheCase: (data) => {
    return http.post("cacheCase", {
      data: data,
    }, {}, "cacheCase");
  },
  detailRecommendPerson: (data) => {
    return http.post("detailRecommendPerson", {
      data: data
    }, {}, "detailRecommendPerson");
  },
  getSinglePostion: (data) => {
    return http.get("getSinglePostion", {
      uid: data
    }, {}, "getSinglePostion");
  },
  GetCP: (data) => {
    return http.get("GetCP/"+data, {}, {}, "GetCP");
  },
  addposition: (data) => {
    return http.post("addPosition", {
      data: data
    }, {}, "addPosition");
  },
  removePosition: (data) => {
    return http.post("removePosition", {
      uid: data
    }, {}, "removePosition");
  },
  issueCase: (uid) => {
    return http.post("issueCase", {
      case_uid: uid
    }, {}, "issueCase");
  },
  updatecase: (data) => {
    // console.log(data);
    return http.post("updatecase", data, {}, "updatecase");
  },
  ShowHistoryRecordTaker: (data) => {
    return http.post("ShowHistoryRecordTaker", data, {}, "ShowHistoryRecordTaker")
  },
  editPosition: (data) => {
    return http.post("editPosition", data, {}, "editPosition")
  },
  responseDiscuss: (data) => {
    return http.post("responseDiscuss", {
      data: data
    }, {}, "responseDiscuss");
  }

}