export default {
  alertbox: {
    'change the content': '修改了職位內容，請去查看',
    "codeOuttime": "驗證碼過期",
    "mistakeCode": "驗證碼錯誤",
    resign:{
        success:"登入成功，驗證碼認證"
    },
    // matching
    'case invite you': '邀請您加入',
    'accept resume': '接受了您的履歷',
    'check invite': '發案方已確認邀請',
    'cancel invite': '已取消對您的邀請',
    // Recruiting
    'accept invite': '接受您的旅團邀請',

    'already pay': '已經付款完畢',
    'send a resume': '投了一封履歷',
    'paid 0': '保證金0元媒合成功',
    // 歷史應徵者
    'refuse invite': "回絕了您的邀請",
    "TakeSide Default": "違約，保證金已退還給您",
    "CaseSide Default": "違約，保證金以退還給您",
    //歷史接案
    'refuse resume': '拒絕了您的履歷',
    'refuse promisemoney': '拒絕付保證金',

    // user / system
    'pls add pic': '頭像尚未上傳',

    // cases
    'all people are pay': '所有接案者已經付款完畢',


    'promisemoney return': '保證金已歸還',
    'default case': '對方違約',
    'default accept': '發案方接受接案方提出違約',
    'default reject': '發案方拒絕接案方提出違約',
    'taker default objection': '接案方有異議',
    'case default objection': '發案方有異議',

    'close': '已經結案',

    'position is full but not pay': '職位滿，但是還有人沒付錢，可以等待',
    'position is full': '職位已滿',

    // company-comment / member-comment
    'have comment': '有一則評論',
    'have ReplyComment': '回復了你的評論',
    'position is null pls add place': '因違約職缺為0請去新增職位',


  },
  TakeCaseStatus: {
    'discuss': '商議中',
    'ready': '備取',
    'accept': '等候回覆',
    'money': '付保證金',
    'confirm': '錄取',
    'carryOut': '執行中',
    'finished': '執行完成',
    'complete': '結案',
    'takerDefault': '接案方違約',
    'caseDefault': '發案方違約',
    'check': '再次確認',
    'refuse': '已拒絕',
    'recommend': '系統推薦',
    "CaseDiscuss":"爭議"
  },
  CaseStatus: {
    'wait': '徵才中',
    'RecruitCompleted': '徵才完畢',
    'carryOut': '執行中',
    'finished': '執行完畢',
    'complete': '結案',
    // 'close': '結案'
  },
  func: {
    'InviteUser': '邀',
    'UserTake': '主'
  },
  msg: {
    'email be in use': '信箱已被使用'
  },
  gender: {
    'male': '男',
    'female': '女'
  },
  margin: {
    'CaseDefaultReturn': '發案方違約收款',
    'TakerDefaultReturn': '接案方違約',
    'TakerDiscuss': '接案方違約',
    "CaseDiscuss":"發案方違約",
    'AddCase': '發案押金',
    'pay promisemoney': '接案押金',
    'Return': '保證金退還',
    'return': '保證金退還',
    'addValue': '加值',
  },
  month: {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  },
  week: {
    '0': '日',
    '1': '一',
    '2': '二',
    '3': '三',
    '4': '四',
    '5': '五',
    '6': '六',
  },

  waring: {
    // case
    'casename': '旅團名稱請勿留空值',
    'category': '旅團類別請勿留空值',
    'date': '出發日期請勿留空值',
    'enddate': '結束日期請勿留空值',
    'location': '地點請勿留空值',
    'departure': '出發地點請勿留空值',
    'connect': '聯絡人請勿留空值',
    'phone': '聯絡電話請勿留空值',

    // CP
    'name': '職位名稱請勿留空值',
    'num': '職位數量請勿留空值',
    'salary': '薪水請勿留空值',
    'promisemoney': '保證金請勿留空值',

    //issue case
    'pls fill in the position': '職位不得空白',
    'pls fill data': '必填欄位不得空白',
  },

  errorstatus: {
    'pending': '待處理',
    'processing': '處理中',
    'solved': '已解決',
  },

}