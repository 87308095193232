import http from "./support";
export default {
  get: (url) => {
    return http.get(url);
  },
  searchlist: (search, page) => {
    return http.post("showperson",{
      search: search,
      page: page
    })
  },
  unLoginShowPerson: (search, page) => {
    return http.post("unLoginShowPerson",{
      search: search,
      page: page
    })
  },
  filterPerson:(search,page,searchtype)=>{
    const url= searchtype;
    // console.log(url)
    return http.post(url,{
      search: search,
      page: page
    });
  },
  showSavePerson:(page,searchdata={},orderBy={name:"createtime",order:"desc"})=>{
    return http.post("showSavePerson",{
      page:page,
      searchdata:searchdata,
      orderBy:orderBy
    })
  },
  recommendCase:(page,searchdata={},orderBy={name:"createtime",order:"desc"})=>{
    return http.post("recommendCase",{
      page:page,
      searchdata:searchdata,
      orderBy:orderBy
    })
  },
  savecase: (data) => {
    return http.post("savePerson", {
      user_uid: data
    });
  },
  inviteUser:(data)=>{
    //data => 
    // case_uid: "CA19011800000V9"
    // message: "您好，誠摯地邀請您來承接旅團。"
    // position_uid: "CP19011800010K1"
    // user_uid: "U18122400010B0"
    return http.post("inviteUser",{data:data});
  },
  getCase:(data)=>{
    return http.get("getCase",{
      page:data
    });
  },
  AllTakeCases:(page,search={},orderBy={name:"createtime",order:"desc"})=>{
    return http.post("AllTakeCases",{
      page:page,
      search:search,
      orderBy:orderBy
    });
  },
  GetAllReviewer:(page,search={},orderBy={name:"createtime",order:"desc"})=>{
    return http.post("GetAllReviewer",{
      page:page,
      search:search,
      orderBy:orderBy
    });
  },
  PayPromisemoney:(data)=>{
    return http.post("promisemoney",{
      data:data
    })
  },
  SendCalendarSaveCaseResume:(data)=>{
    // console.log(data);
    
    return http.post('SendCalendarSaveCaseResume',{
      data:data
    })
  },
  takerDefault:(data)=>{
    return http.post("takerDefault",{
      data:data
    })
  },
  takerObjection:(data)=>{
    return http.post("takerObjection",{
      data:data
    })
  },
  acceptInvite:(data)=>{
    return http.post("acceptInvite",{
      data:data
    })
  },
  showHistoryRecordComplete:(page)=>{
    return http.get('showHistoryRecordComplete',{
      page:page
    });
  },
  TakerDiscuss:(page)=>{
    return http.get('TakerGetObjectionList',{
      page:page
    });
  },
  detailPerson:(data)=>{
    return http.post('detailPerson',{
      user_uid:data.user_uid,
      currentPage:data.currentPage,
    })
  },
  findPersonByEmail:(data)=>{
    return http.get("findPersonByEmail",data);
  },
  showcalendar:(data)=>{
    return http.post('showcalendar',{
      data
    })
  },
  CalendarAlgorithmCase:(data)=>{
    return http.get('CalendarAlgorithmCase')
  },
  AddCalendarItem:(data)=>{
    return http.post('addSchedule',{
      data
    })
  },
  showresume:(data)=>{
    return http.get('showresume');
  },
  showCard:()=>{
    return http.get('showCard');
  },
  cancelTake:(data)=>{
    return http.post("cancelTake",data)
  }

  
}