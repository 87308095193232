import http from "./support";
export default {
  get: (url) => {
    return http.get(url);
  },
  licenceName: (name = "") => {
    //name =>證照類別
    return http.get("licenceName", {
      category: name
    });
  },
  // /publicrequest?name=case_code
  Category: () => {
    return http.get("licenceCategory");
  },
  street: (name = "") => {
    //name = > city
    return http.get("street", {
      name: name
    });
  },
  country: (search) => {
    return http.get("county", {
      search: search
    });
  },
  publicClass: (name) => {
    return http.get("publicrequest", {
      name: name
    });
  },
  getAirport: () => {
    return http.get("getAirport");
  },
  getPort: () => {
    return http.get("getPort");
  },
  evaluation: (data) => {
    return http.get("ShowEvaluation", {
      belong_uid: data.belong_uid,
      page: data.page,
      method: data.method
    });
  },
  hotCase: () => {
    return http.get("hotCase")
  },
  hotPerson: () => {
    return http.get("hotPerson")
  },
  Evaluate: (data) => {
    return http.post("Evaluate", data);
  },
  transaction: (search, page, currentPage) => {
    // console.log(search,page,currentPage)
    return http.get("transaction", {
      "category": search.category,
      "order": search.order,
      "sort": search.sort,
      "page": page,
      "currentPage": currentPage,
    });
  },
  transactionInfo: (data) => {
    return http.get("transactionInfo", data);
  },
}
